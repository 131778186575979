import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { postItem } from "../_redux/authCrud";
import { getQueryParams } from "../../../../_metronic/_helpers";

function VerifyEmail(props) {
  const queryParams = getQueryParams();

  const initialValues = {
    user_id: queryParams.user_id,
    timestamp: queryParams.timestamp,
    signature: queryParams.signature,
  };

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [redirectTo, redirect] = useState("");
  const VerifyEmailSchema = Yup.object().shape({
    user_id: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    timestamp: Yup.number()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    signature: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: VerifyEmailSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      const formData = new FormData();
      formData.set("user_id", values.user_id);
      formData.set("timestamp", values.timestamp);
      formData.set("signature", values.signature);

      postItem("/accounts/verify-registration/", formData)
        .then(() => {
          redirect(`/auth/login?message=email-verified`);
        })
        .catch((err) => {
          if (err.smart) {
            setStatus(err.json.detail);
          } else {
            setStatus(err.message);
          }
          setSubmitting(false);
          setLoading(false);
        });
    },
  });

  React.useEffect(() => {
    formik.handleSubmit();
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: user_id */}
        <input
          type="hidden"
          name="user_id"
          {...formik.getFieldProps("user_id")} />
        {/* end: user_id */}

        {/* begin: timestamp */}
        <input
          type="hidden"
          name="timestamp"
          {...formik.getFieldProps("timestamp")} />
        {/* end: timestamp */}

        {/* begin: signature */}
        <input
          type="hidden"
          name="signature"
          {...formik.getFieldProps("signature")} />
        {/* end: signature */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Back
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(VerifyEmail));
