import React, { ReactElement } from "react";
import Loading from "../../../Loading";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as auth from "../_redux/authRedux";
import { getURLParam } from "../../../services/getURLParam";
import { CreateBaseSiteUrl } from "../../../constraints";

const Logout = ({ logout }: any): ReactElement => {
  const authToken = useSelector(
      ({ auth: userAuth }: any) => {
          (window as any).API_TOKEN = userAuth.authToken || null;
          return userAuth.authToken;
      },
      shallowEqual
  );

  React.useEffect(() => {
    if (authToken) {
      logout();
    } else {
      const returnTo = getURLParam("returnTo");
      window.location.replace(CreateBaseSiteUrl(returnTo || "/", {
        loggedout: true,
      }));
    }
  }, [authToken]);

  return <Loading />;
};

export default connect(
  ({ auth }: any) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
