/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";

export function AnalyticsChart({ className, analyticsData, title, description, id, unit }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      primary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      secondary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.secondary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, analyticsData, unit);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, analyticsData]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">{title}</span>
            <span className="text-muted font-weight-bold mt-2">
              {description}
            </span>
          </div>
        </div>
        <div
          id={id}
          className="card-rounded-bottom"
          style={{ height: "150px" }}
        ></div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, analyticsData, unit) {
  const lineColour = [layoutProps.primary, layoutProps.secondary];
  const dotColour = lineColour;

  let labels = [];
  if(analyticsData && analyticsData[0] && analyticsData[0].data) {
    labels = analyticsData[0].data.map((d, i) => {
      const labelItems = [];
      analyticsData.forEach(({ data }) => {
        data.forEach(({ label }, dI) => {
          if (dI === i) labelItems.push(label);
        });
      });

      if (labelItems.join("") === labelItems[0]) {
        return labelItems[0];
      }

      return labelItems.join(" vs. ");
    });
  }

  const options = {
    series: analyticsData.map((data) => (
      {
        name: data.title,
        data: data.data.map((a) => a.value)
      }
    )),
    chart: {
      type: "area",
      height: 500,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0.5
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return `${val} ${unit}`;
        }
      }
    },
    colors: dotColour,
    markers: {
      strokeWidth: 3
    }
  };
  return options;
}
