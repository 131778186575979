const { REACT_APP_API_URL, REACT_APP_BASE_URL, TEST_SITE } = process.env

export const BASE_SITE = REACT_APP_BASE_URL;

export const API_BASE = REACT_APP_API_URL;

export const COOKIE_DOMAIN = ".therighttool.co.nz";
export const COOKIE_SECURE = true;

export const IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png"
];

const TEST_STRIPE_KEY = "pk_test_51HzZz8FxvGDwozLyFdixt46FTb1i4aUqko9i0VintOkQgO11teGn8DdCsYuqZrGdBYaLMFoEki9nAO8hK0NaPnME00cesK3cOq";
const LIVE_STRIPE_KEY = "pk_live_51HzZz8FxvGDwozLyv7RXSpD1cGr9krx7QEsdyv94dZkcApZZJpQevWa0HPnRNbEFuma90X0n1s9x5416St6ZMMpV00jFFxupWX";
export const STRIPE_KEY = TEST_SITE ? TEST_STRIPE_KEY : LIVE_STRIPE_KEY;

export const SITE_PLANS = {
  annually: {
    slug: "prod_Ialvh4sP55J9uy",
    price: 199.00,
    comparePrice: 399.99,
    coupon: "fVMhAM4f",
    firstInterval: "first year",
    interval: "year",
    intervalText: "annually",
    title: "Premium Account",
    features: {
      appListings: -1,
      serviceListings: -1,
    }
  },

  quarterly: {
    slug: "prod_Ialvh4sP55J9uy",
    price: 149.00,
    comparePrice: 0,
    coupon: "",
    firstInterval: "/ quarter",
    interval: "quarter",
    intervalText: "quarterly",
    title: "Premium Account",
    features: {
      appListings: -1,
      serviceListings: -1,
    }
  },
};

export const SPECIAL_OFFERS = {
  30: {
    duration: "30 days",
    price: 9.00,
  },

  90: {
    duration: "90 days",
    price: 24.00,
  },

  365: {
    duration: "365 days",
    price: 89.00,
  },
} as {
  [name: number]: {
    duration: string;
    price: number;
  }
};

export const CreateBaseSiteUrl = (url: string, query?: {
    [name: string]: string | boolean | number,
}) => {
  const queryString =
    query
      ?  Object.entries(query)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&")
      : "";

  return `${BASE_SITE}${url}${queryString ? `?${queryString}`: ""}`;
};

export enum AppBroadcastChannelEvent {
  CardAdded = "CardAdded",
}

export const AppBroadcastChannel = "BroadcastChannel" in window && new BroadcastChannel("AppBroadcastChannel");
