import React, { useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { postItem, deleteItem, getIndustries } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loading from "../../Loading";

import { IIndustry } from "../../../interface/IIndustry";

export const Industries = (): React.ReactElement => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Category");

  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState<IIndustry[]>([]);

  React.useEffect(() => {
    getIndustries().then((newIndustries) => {
      setIndustries(newIndustries);
      setLoading(false);
    });
  }, []);

  const initialValues = {
    name: "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string().required("Industry name is required"),
  });
  const getInputClasses = (fieldname: string) => {
    if ((formik.touched as any)[fieldname] && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if ((formik.touched as any)[fieldname] && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      const form = document.getElementById("listing-form") as HTMLFormElement;
      const formData = new FormData(form);

      postItem("/industries/", formData)
        .then(() => {
          getIndustries().then((newIndustries) => {
            setIndustries(newIndustries);
            setLoading(false);
          });
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(err);
          if (err.smart) {
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const checkDelete = (item: IIndustry) => {
    return () => {
      if (confirm(`Delete ${item.name}?`)) {
        setLoading(true);
        deleteItem(item).then(() => {
          getIndustries().then((newIndustries) => {
            setIndustries(newIndustries);
            setLoading(false);
          });
        });
      }
    };
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={`card card-custom`}>
        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center mt-5">
                <tbody>
                  {industries.map((industry) => {
                    return (
                      <tr key={industry.url}>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">{industry.name}</span>
                            </div>
                          </div>
                        </td>
                        <td className="pr-0 text-right">
                          <span onClick={checkDelete(industry)} className="btn btn-danger font-weight-bolder font-size-sm">Delete</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <form
        className="card card-custom mt-5"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="form-group row mb-0">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Industry
              </label>
              <div className="col-lg-9 row">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "name"
                  )}`}
                  style={{
                    flex: 1,
                  }}
                  {...formik.getFieldProps("name")}
                />

                <button
                  type="submit"
                  className="btn btn-success ml-3"
                  disabled={
                    formik.isSubmitting || (formik.touched && !formik.isValid)
                  }
                >
                  {"Add Industry"}
                </button>

                {touched.name && errors.name ? (
                  <div className="invalid-feedback">
                    {errors.name}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>
    </>
  );
};
