import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSubheader } from "../../../_metronic/layout";
import { createListing, getIndustries, getListing, getCategories, getSubcategories, patchItem, deleteItem } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Loading from "../../Loading";
import { IMAGE_TYPES } from "../../constraints";

import { SpecialOffers } from "./SpecialOffersComponent";

import { IAppListing } from "../../../interface/IAppListing";
import { IIndustry } from "../../../interface/IIndustry";
import { ISubcategory } from "../../../interface/ISubcategory";
import { ICategory } from "../../../interface/ICategory";
import { ISpecialOffer } from "../../../interface/ISpecialOffer";

export const AppView = (props: any) => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Listing");

  const [query] = useState(window.location.search);

  const [isLoaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [listing, setListing] = useState<IAppListing>({} as any);
  const [specialOffers, setSpecialOffers] = useState<ISpecialOffer[]>([]);

  const [message, setMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: "info",
    message: "",
  });
  const [redirectTo, redirect] = useState("");
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
  const [nzOwned, setNzOwned] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [listingStatus, setListingStatus] = useState("P");

  const initialValues = {
    name: "",
    nz_owned: false,
    short_description: "",
    long_description: "",
    link: "",
    free_trial: "",
    pricing: "",
    status: "P",
    admin_note: "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string().required("Listing name is required"),
    nz_owned: Yup.boolean(),
    short_description: Yup.string().required("Short description is required"),
    long_description: Yup.string().required("Full description is required"),
    link: Yup.string().url("Must be a valid link").required("Link is required"),
    free_trial: Yup.string().url("Must be a valid link"),
    pricing: Yup.string().required("Pricing is required"),
    industries: Yup.array(Yup.string()),
    subcategories: Yup.array(Yup.string()),
    logo: Yup
      .mixed()
      .test(
        "fileFormat",
        "Unsupported Format: Must be JPEG or PNG",
        () => {
          const fileInput = document.getElementById("listing-form-logo") as HTMLInputElement;
          const files = Array.from(fileInput.files || [] as any as FileList);
          const badFiles = files.filter((f) => !IMAGE_TYPES.includes(f.type));
          return badFiles.length === 0;
        }
      ),
  });
  const getInputClasses = (fieldname: string, disableTouch?: boolean) => {
    if (((formik.touched as any)[fieldname] || disableTouch) && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if (((formik.touched as any)[fieldname] || disableTouch) && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setMessage({
        type: "info",
        message: "",
      });

      const form = document.getElementById("listing-form") as HTMLFormElement;
      const formLogo = document.getElementById("listing-form-logo") as HTMLInputElement;

      const formData = new FormData(form);
      if (!formLogo.value) {
        formData.delete("logo");
      }
      formData.set("nz_owned", nzOwned ? "true" : "false");

      const isNewListing = !listing.url;
      let baseFunction;
      if (isNewListing) baseFunction = createListing(formData, false);
      else baseFunction = patchItem(listing, formData);

      baseFunction
        .then(() => {
          setSubmitting(false);
          setListing(listing);
          setSpecialOffers([
            ...listing.special_offers || [],
            ...listing.pending_special_offers || []
          ]);
          setStatus("success");
          setMessage({
            type: "success",
            message: (user && user.admin) ? "Listing updated." : "Listing successfully submitted for review."
          });

          if (isNewListing) {
            redirect("/apps/pending");
          }
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(err);
          if (err.smart) {
            setMessage({
              type: "danger",
              message: "Could not create or update listing. See below for more information.",
            });
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
            setMessage({
              type: "danger",
              message: err.message,
            });
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const { listingId } = props;
  const isNew = !listingId;

  const categoryIsSelected = (selected: string, current: string) => {
    if (current === selected) {
      return true;
    }

    // TEMP v2 CONVERT
    const selectedAsV1 =
      selected
        .split("/")
        .filter((p) => p !== "v2")
        .join("/");
    const currentAsV1 =
      current
        .split("/")
        .filter((p) => p !== "v2")
        .join("/");

    if (selectedAsV1 === currentAsV1) {
      return true;
    }

    return false;
  }

  const subCategoryIsSelected = (selected: string, current: string[]) => {
    if (current.includes(selected)) {
      return true;
    }

    // TEMP v2 CONVERT
    const selectedAsV1 =
      selected
        .split("/")
        .filter((p) => p !== "v2")
        .join("/");

    if (current.includes(selectedAsV1)) {
      return true;
    }

    return false;
  }

  const checkDelete = () => {
    if (confirm(`Delete ${listing.name}?`)) {
      setLoading(true);
      deleteItem(listing).then(() => {
        redirect(`/apps/${props.isPending ? "pending" : "active"}`);
      });
    }
  }

  React.useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);

      getIndustries().then(setIndustries);
      getCategories().then(setCategories);

      if (isNew) {
        getSubcategories().then(setSubcategories);
        setLoading(false);
      } else if(!listing.name) {
        setLoading(true);

        let baseFunction;
        if (props.isPending) baseFunction = getListing(`/pending/apps/${listingId}/`);
        else baseFunction = getListing(`/apps/${listingId}/`);

        baseFunction
          .then((listingInfo) => {
            const subcategoriesUrls = listingInfo.subcategories.map((category: any) => category.url) as string[];
            const selected =
              (listingInfo.subcategories
                ? listingInfo.subcategories[0]
                : {}) as any as ISubcategory;

            const newValues = {
              ...listingInfo,
              industries: listingInfo.industries.map((industry: any) => industry.url) as any,
              subcategories: subcategoriesUrls,
              admin_note: listingInfo.admin_note || "",
            };

            formik.setValues(newValues as any);
            setListing(newValues);
            setSpecialOffers([
              ...listing.special_offers || [],
              ...listing.pending_special_offers || []
            ]);
            setNzOwned(listingInfo.nz_owned);
            setListingStatus(listingInfo.status as string);
            setLoading(false);

            getSubcategories().then((sub) => {
              setSubcategories(sub);

              const selectedSub = sub.find((s) => categoryIsSelected(selected.url, s.url));

              if (selectedSub) {
                setSelectedCategory(selectedSub.category);
              }
            });
          });
      }
    }
  }, [listingId, isNew, formik, isLoaded]);

  React.useEffect(() => {
    if (formik.values.status !== listingStatus) {
      formik.setFieldValue("status", listingStatus);
    }
  }, [listingStatus]);

  React.useEffect(() => {
    setListing((currentListing) => {
      if (!selectedCategory || !subcategories) {
        return currentListing;
      }
  
      const fullDetails =
        subcategories
          .filter((category) => categoryIsSelected(category.category, selectedCategory))
          .filter((category) => {
            return currentListing.subcategories && subCategoryIsSelected(category.url, currentListing.subcategories);
          });

      return {
        ...currentListing,
        subcategories: fullDetails.map((f) => f.url),
      };
    });
  }, [selectedCategory, subcategories]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <form
        className="card card-custom"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Listing Information
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Information about this listing
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={
                formik.isSubmitting || (formik.touched && !formik.isValid)
              }
            >
              {isNew ? (user.admin ? "Create listing" : "Submit for review") : "Save changes"}
              {formik.isSubmitting}
            </button>
            <Link
              to={`/apps/active${query}`}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            {message && message.message &&
              <div className={`mb-10 alert alert-custom alert-light-${message.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">{message.message}</div>
              </div>}

            {listing.status === "D" &&
              <div className={`mb-10 alert alert-custom alert-danger alert-dismissible`}>
                <div className="alert-text font-weight-bold">
                  <div>This listing has been declined by an admin. {listing.admin_note ? "The following note was left:" : "No note was left."}</div>
                  <div>{listing.admin_note}</div>
                </div>
              </div>}

            {
              (listing.special_offers || [])
                .filter((s) => s.status === "D")
                .map((specialOffer) =>
                  <div className={`mb-10 alert alert-custom alert-danger alert-dismissible`} key={specialOffer.url}>
                    <div className="alert-text font-weight-bold">
                      <div>The special offer has been declined by an admin. {listing.admin_note ? "The following note was left:" : "No note was left."}</div>
                      <div>{specialOffer.admin_note}</div>
                    </div>
                  </div>)
            }

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Listing name
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  placeholder="My awesome product"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "name"
                  )}`}
                  {...formik.getFieldProps("name")}
                />
                {touched.name && errors.name ? (
                  <div className="invalid-feedback">
                    {errors.name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Logo
              </label>
              <div className="col-lg-9">
                <input
                  type="file"
                  accept="image/*"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "logo",
                    true,
                  )}`}
                  name="logo"
                  id="listing-form-logo"
                  onChange={formik.handleChange}
                />
                {errors.logo ? (
                  <div className="invalid-feedback">
                    {errors.logo}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Short description
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "short_description"
                  )}`}
                  {...formik.getFieldProps("short_description")}
                />
                {touched.short_description && errors.short_description ? (
                  <div className="invalid-feedback">
                    {errors.short_description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Full description
              </label>
              <div className="col-lg-9">
                <textarea
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "long_description"
                  )}`}
                  {...formik.getFieldProps("long_description")}
                />
                {touched.long_description && errors.long_description ? (
                  <div className="invalid-feedback">
                    {errors.long_description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link
              </label>
              <div className="col-lg-9">
                <input
                  type="link"
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "link"
                  )}`}
                  {...formik.getFieldProps("link")}
                />
                {touched.link && errors.link ? (
                  <div className="invalid-feedback">
                    {errors.link}
                  </div>
                ) : null}
              </div>
            </div>
  
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Free trial
              </label>
              <div className="col-lg-9">
                <input
                  type="link"
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "free_trial"
                  )}`}
                  {...formik.getFieldProps("free_trial")}
                />
                {touched.free_trial && errors.free_trial ? (
                  <div className="invalid-feedback">
                    {errors.free_trial}
                  </div>
                ) : null}
              </div>
            </div>
  
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Pricing
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  placeholder="$29.99/month"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "pricing"
                  )}`}
                  {...formik.getFieldProps("pricing")}
                />
                {touched.pricing && errors.pricing ? (
                  <div className="invalid-feedback">
                    {errors.pricing}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Industries
                <br />
                <em className="text-muted">Max 1</em>
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-0"
                  classNamePrefix="select"
                  isLoading={industries.length === 0}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={
                    industries
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  value={
                    industries
                      .filter((app) => (listing.industries || []).includes(app.url))
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  onChange={(value) => {
                    const newApps = value ? [value.value] : [];
                    setListing({
                      ...listing,
                      industries: newApps,
                    });
                  }}
                  noOptionsMessage={() => "No results found"}
                />

                {industries && industries.map((app) => {
                  const checked = ((listing.industries || []) as any as string[]).includes(app.url);
                  return (
                    <input
                      key={app.url}
                      className="d-none"
                      type="checkbox"
                      name="industries"
                      value={app.url}
                      checked={checked}
                      onChange={() => {
                        // null
                      }}
                    />
                  );
                })}

                {touched.industries && errors.industries ? (
                  <div className="invalid-feedback">
                    {errors.industries}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Category
                <br />
                <em className="text-muted">Max 1</em>
              </label>
              <div className="col-lg-9">
                {categories && categories.map((category, i) => (
                  <div key={category.url}>
                    <label key={category.url} className={`d-flex align-items-center ${categories.length === (i + 1) ? "mb-0" : ""}`}>
                      <label className="checkbox checkbox-lg checkbox-circle flex-shrink-0 m-0 mr-4">
                        <input
                          type="radio"
                          name="category"
                          value={category.url}
                          onChange={() => setSelectedCategory(category.url)}
                          checked={categoryIsSelected(category.url, selectedCategory)}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>{category.name}</span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {selectedCategory &&
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Subcategories
                  <br />
                  <em className="text-muted">Max 3</em>
                </label>
                <div className="col-lg-9">
                  <Select
                    className="basic-single mb-0"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={
                      (listing.subcategories || []).length >= 3
                        ? []
                        : subcategories
                          .filter((app) => app.category === selectedCategory)
                          .map((app) => ({
                            label: app.name,
                            value: app.url,
                          }))
                    }
                    value={
                      subcategories
                        .filter((app) => (listing.subcategories || []).includes(app.url))
                        .map((app) => ({
                          label: app.name,
                          value: app.url,
                        }))
                    }
                    onChange={(values) => {
                      const newApps = values ? values.map((value) => value.value) : [];
                      setListing({
                        ...listing,
                        subcategories: newApps,
                      });
                    }}
                    noOptionsMessage={() => {
                      if ((listing.subcategories || []).length >= 3) {
                        return "Max 3 already selected";
                      }

                      return "No results found";
                    }}
                  />

                  {subcategories && subcategories.map((app) => {
                    const checked = ((listing.subcategories || []) as any as string[]).includes(app.url);
                    return (
                      <input
                        key={app.url}
                        className="d-none"
                        type="checkbox"
                        name="subcategories"
                        value={app.url}
                        checked={checked}
                        onChange={() => {
                          // null
                        }}
                      />
                    );
                  })}

                  {touched.subcategories && errors.subcategories ? (
                    <div className="invalid-feedback">
                      {errors.subcategories}
                    </div>
                  ) : null}
                </div>
              </div>
           }

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                NZ Owned
              </label>
              <div className="col-lg-9">
                <label className="mb-0 alert alert-custom alert-outline-info">
                  <div className="alert-text font-weight-bold">
                    <label className="d-flex align-items-center m-0">
                      <label className="checkbox checkbox-lg flex-shrink-0 m-0 mr-4">
                        <input
                          type="checkbox"
                          name="nz_owned"
                          onChange={() => {
                            setNzOwned(!nzOwned);
                          }}
                          checked={nzOwned}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>This listing is New Zealand owned and operated</span>
                      </div>
                    </label>
                  </div>
                </label>
              </div>
            </div>

            {user && user.admin && listing.status &&
              <>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Status
                  </label>
                  <div className="col-lg-9">
                    <input type="hidden" {...formik.getFieldProps("status")} />
                    {
                      [
                        ["Pending", "P", "primary"],
                        ["Approved", "A", "success"],
                        ["Declined", "D", "danger"],
                      ].map(([title, code, className]) =>
                        <button key={code} type="button" className={`btn btn-${listingStatus === code ? className : "secondary"} mr-3`} onClick={() => setListingStatus(code)}>{title}</button>
                      )
                    }
                  </div>
                </div>

                {listingStatus === "D" &&
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Reason for decline
                    </label>
                    <div className="col-lg-9">
                      <textarea
                        placeholder=""
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "admin_note"
                        )}`}
                        {...formik.getFieldProps("admin_note")}
                      />
                      {touched.admin_note && errors.admin_note ? (
                        <div className="invalid-feedback">
                          {formik.errors.admin_note}
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              </>
            }
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>

      {/* Special Offers component */}
      <SpecialOffers
        listing={listing}
        listingType="apps"
        specialOffers={specialOffers}
        setSpecialOffers={setSpecialOffers.bind(this)}
        setMessage={setMessage.bind(this)}
      />

      {listing.url &&
        <div className="card card-custom mt-5">
          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Danger Zone
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Permanently delete this listing
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={checkDelete}
                className="btn btn-danger">
                {"Delete"}
              </button>
            </div>
          </div>
          {/* end::Header */}
        </div>
      }
    </>
  );
};
