/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { CreateBaseSiteUrl } from "../../../constraints";

import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import VerifyEmailGate from "./VerifyEmailGate";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";

export function AuthPage() {
  const today = new Date().getFullYear();
  const [verifyEmailToken, setVerifyEmailToken] = useState("");

  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-lg-flex flex-column flex-lg-row flex-row-fluid bg-white"
              id="kt_login"
          >
            {/*begin::Aside*/}
            <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{
                  background: `linear-gradient(92.49deg, #0d4461d7 0%, #0078afe7 100%), url(${toAbsoluteUrl("/media/bg/filtermain.759d649e.png")})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <a href={CreateBaseSiteUrl("/")} className="flex-column-auto mt-5">
                  <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/logo-light.png")}
                  />
                </a>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <h3 className="font-size-h1 mb-5 text-white">
                    Customer portal
                  </h3>
                  <p className="font-weight-lighter text-white opacity-80">
                    Manage and add listings
                  </p>
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="opacity-70 font-weight-bold	text-white">
                    &copy; {today.toString()} The Right Tool
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              {/*begin::Content header*/}
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <Link to={`/auth/registration${window.location.search}`} className="font-weight-bold" id="kt_login_signup">Create an account</Link>
                <span className="mx-5 text-muted">|</span>
                <Link to={`/auth/login${window.location.search}`} className="font-weight-bold" id="kt_login_signup">Sign In</Link>
              </div>
              {/*end::Content header*/}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                {verifyEmailToken
                  ? <Redirect from="/auth/login" to="/auth/verify-email" />
                  : <ContentRoute path="/auth/login" render={() => <Login onVerifyEmail={(token) => setVerifyEmailToken(token)}/>}/>}

                {verifyEmailToken
                  ? <ContentRoute path="/auth/verify-email" render={() => {
                    return <VerifyEmailGate onBack={() => setVerifyEmailToken(null)} />;
                  }} />
                  : <Redirect from="/auth/verify-email" to="/auth/login" />}

                <ContentRoute path="/auth/verify" component={VerifyEmail} />
                <ContentRoute path="/auth/reset-password" component={ResetPassword} />
                <ContentRoute path="/auth/registration" component={Registration} />
                <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login"/>
                <Redirect to="/auth/registration"/>
              </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div
                  className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; {today.toString()} The Right Tool
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
