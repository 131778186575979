import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { postItem } from "../_redux/authCrud";
import { getQueryParams } from "../../../../_metronic/_helpers";

function ResetPassword(props) {
  const queryParams = getQueryParams();

  const initialValues = {
    password: "",
    password_confirm: "",
    user_id: queryParams.user_id,
    timestamp: queryParams.timestamp,
    signature: queryParams.signature,
  };

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [redirectTo, redirect] = useState("");
  const VerifyEmailSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password_confirm: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
            intl.formatMessage({
              id: "AUTH.VALIDATION.PASSWORD_MATCH",
            })
        ),
      }),
    user_id: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    timestamp: Yup.number()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    signature: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: VerifyEmailSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      const formData = new FormData();
      formData.set("user_id", values.user_id);
      formData.set("timestamp", values.timestamp);
      formData.set("signature", values.signature);
      formData.set("password", values.password);
      formData.set("password_confirm", values.password_confirm);

      postItem("/accounts/reset-password/", formData)
        .then(() => {
          redirect(`/auth/login?message=password-reset`);
        })
        .catch((err) => {
          if (err.smart) {
            setStatus(err.json[0]);
          } else {
            setStatus(err.message);
          }
          setSubmitting(false);
          setLoading(false);
        });
    },
  });

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.RESET.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.RESET.DESC" />
        </p>
      </div>
      {/* end::Head */}

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: password */}

        {/* begin: password_confirm */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password_confirm"
            )}`}
            name="password_confirm"
            {...formik.getFieldProps("password_confirm")}
          />
          {formik.touched.password_confirm && formik.errors.password_confirm ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.password_confirm}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: password_confirm */}

        {/* begin: user_id */}
        <input
          type="hidden"
          name="user_id"
          {...formik.getFieldProps("user_id")} />
        {/* end: user_id */}

        {/* begin: timestamp */}
        <input
          type="hidden"
          name="timestamp"
          {...formik.getFieldProps("timestamp")} />
        {/* end: timestamp */}

        {/* begin: signature */}
        <input
          type="hidden"
          name="signature"
          {...formik.getFieldProps("signature")} />
        {/* end: signature */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Back
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
