import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { getListings } from "./modules/Auth/_redux/authCrud";
import { shallowEqual, useSelector } from "react-redux";

import { DashboardView } from "./pages/Dashboard";
import { Featured } from "./pages/Listings/Featured";

import { ServicesPaywall } from "./pages/Paywall/ServicesPaywall";
import { TrialPaywall } from "./pages/Paywall/TrialPaywall";
import { SpecialOfferPaywall } from "./pages/Paywall/SpecialOfferPaywall";

import { Apps } from "./pages/Listings/Apps";
import { AppView } from "./pages/Listings/AppView";

import { Services } from "./pages/Listings/Services";
import { ServiceView } from "./pages/Listings/ServiceView";

import { AnalyticsView } from "./pages/Listings/AnalyticsView";

import { Reviews } from "./pages/Reviews/Reviews";
import { SpecialOffers } from "./pages/SpecialOffers/SpecialOffers";

import { Claims } from "./pages/Claims/Claims";

import { Categories } from "./pages/Metadata/Categories";
import { Category } from "./pages/Metadata/Category";
import { Industries } from "./pages/Metadata/Industries";
import { Locations } from "./pages/Metadata/Locations";

import { AccountPage } from "./pages/AccountPage";

import { Error404 } from "./pages/404";
import { ReviewView } from "./pages/Reviews/ReviewView";

export default function BasePage(): React.ReactElement {
    const user = useSelector((state: any) => state.auth.user, shallowEqual);

    const defaultQuery = "?user=me";

    const getApps = React.useMemo(() => getListings("/apps/"), []);
    const getPendingApps = React.useMemo(() => getListings("/pending/apps/"), []);

    const getServices = React.useMemo(() => getListings("/services/"), []);
    const getPendingServices = React.useMemo(() => getListings("/pending/services/"), []);

    if (!user) {
        return <LayoutSplashScreen />;
    }

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <Redirect exact from="/" to={
                  user.admin
                    ? "/dashboard"
                    : "/apps/active?user=me"
                } />

                {/* Dashboard */}
                <ContentRoute path="/dashboard" component={DashboardView} />

                {/* Featured */}
                <ContentRoute path="/featured" component={Featured} />

                {/* Paywall */}
                <ContentRoute path="/paywall/services" component={ServicesPaywall} />
                <ContentRoute path="/paywall/trial" component={TrialPaywall} />
                <ContentRoute path="/paywall/special-offer" component={SpecialOfferPaywall} />

                {/* App Listings */}
                <ContentRoute path="/apps/new" component={AppView}/>
                <ContentRoute path="/apps/pending/view/:listingId" render={
                    (props: any) =>
                        <AppView
                            listingId={props.match.params.listingId}
                            isPending={true} />
                } />
                <ContentRoute path="/apps/pending/analytics/:listingId" render={
                    (props: any) =>
                        <AnalyticsView
                            listingId={props.match.params.listingId}
                            listingType="apps"
                            isPending={true} />
                } />
                <ContentRoute path="/apps/pending" render={
                    () =>
                        <Apps
                            key="/apps/pending"
                            title="Pending Listings"
                            caption="Listings requiring approval"
                            fn={getPendingApps}
                            linkTo={(query: string) => `/apps/active${query}`}
                            linkText="Active listings"
                            createLink={(query: string) => `/apps/new${query}`}
                            viewLink={(id: string) => `/apps/pending/view/${id}`}
                            analyticsLink={(id: string) => `/apps/pending/analytics/${id}`}
                            query={window.location.search || defaultQuery} />
                } />
                <ContentRoute path="/apps/active/view/:listingId" render={
                    (props: any) =>
                        <AppView
                            listingId={props.match.params.listingId}
                            isPending={false} />
                } />
                <ContentRoute path="/apps/active/analytics/:listingId" render={
                    (props: any) =>
                        <AnalyticsView
                            listingId={props.match.params.listingId}
                            listingType="apps"
                            isPending={false} />
                } />
                <ContentRoute path="/apps/active" render={
                    () =>
                        <Apps
                            key="/apps/active"
                            title="Active Listings"
                            caption="Your active listings on The Right Tool homepage"
                            fn={getApps}
                            linkTo={(query: string) => `/apps/pending${query}`}
                            linkText="View pending"
                            createLink={(query: string) => `/apps/new${query}`}
                            viewLink={(id: string) => `/apps/active/view/${id}`}
                            analyticsLink={(id: string) => `/apps/active/analytics/${id}`}
                            query={window.location.search || defaultQuery} />
                } />

                {/* Service Listings */}
                <ContentRoute path="/services/new" component={ServiceView}/>
                <ContentRoute path="/services/pending/view/:listingId" render={
                    (props: any) =>
                        <ServiceView
                            listingId={props.match.params.listingId}
                            isPending={true} />
                } />
                <ContentRoute path="/services/pending/analytics/:listingId" render={
                    (props: any) =>
                        <AnalyticsView
                            listingId={props.match.params.listingId}
                            listingType="services"
                            isPending={true} />
                } />
                <ContentRoute path="/services/pending" render={
                    () =>
                        <Services
                            key="/services/pending"
                            title="Pending Listings"
                            caption="Listings requiring approval"
                            fn={getPendingServices}
                            linkTo={(query: string) => `/services/active${query}`}
                            linkText="Active listings"
                            createLink={(query: string) => `/services/new${query}`}
                            viewLink={(id: string) => `/services/pending/view/${id}`}
                            analyticsLink={(id: string) => `/services/pending/analytics/${id}`}
                            query={window.location.search || defaultQuery} />
                } />
                <ContentRoute path="/services/active/view/:listingId" render={
                    (props: any) =>
                        <ServiceView
                            listingId={props.match.params.listingId}
                            isPending={false} />
                } />
                <ContentRoute path="/services/active/analytics/:listingId" render={
                    (props: any) =>
                        <AnalyticsView
                            listingId={props.match.params.listingId}
                            listingType="services"
                            isPending={false} />
                } />
                <ContentRoute path="/services/active" render={
                    () =>
                        <Services
                            key="/services/active"
                            title="Active Listings"
                            caption="Your active listings on The Right Tool homepage"
                            fn={getServices}
                            linkTo={(query: string) => `/services/pending${query}`}
                            linkText="View pending"
                            createLink={(query: string) => `/services/new${query}`}
                            viewLink={(id: string) => `/services/active/view/${id}`}
                            analyticsLink={(id: string) => `/services/active/analytics/${id}`}
                            query={window.location.search || defaultQuery} />
                } />

                {/* App Reviews */}
                <ContentRoute path="/reviews/apps/new" render={
                    () =>
                        <ReviewView
                            key="/reviews/apps/new"
                            baseName="/apps/"
                            isPending={false} />
                }/>
                <ContentRoute path="/reviews/apps/pending/view/:reviewId" render={
                    (props: any) =>
                        <ReviewView
                            key="/reviews/apps/pending/view"
                            baseName="/apps/"
                            reviewId={props.match.params.reviewId}
                            isPending={true} />
                } />
                <ContentRoute path="/reviews/apps/pending" render={
                    () =>
                        <Reviews
                            key="/reviews/apps/pending"
                            title="Pending Reviews"
                            caption="Reviews requiring approval"
                            baseName="/apps/"
                            isPending={true} />
                } />
                <ContentRoute path="/reviews/apps/active/view/:reviewId" render={
                    (props: any) =>
                        <ReviewView
                            key="/reviews/apps/active/view"
                            baseName="/apps/"
                            reviewId={props.match.params.reviewId}
                            isPending={false} />
                } />
                <ContentRoute path="/reviews/apps/active" render={
                    () =>
                        <Reviews
                            key="/reviews/apps/active"
                            title="Reviews"
                            caption="Reviews posted on The Right Tool"
                            baseName="/apps/"
                            isPending={false} />
                } />

                {/* Service Reviews */}
                <ContentRoute path="/reviews/services/new" render={
                    () =>
                        <ReviewView
                            key="/reviews/services/new"
                            baseName="/services/"
                            isPending={false} />
                }/>
                <ContentRoute path="/reviews/services/pending/view/:reviewId" render={
                    (props: any) =>
                        <ReviewView
                            key="/reviews/services/pending/view"
                            baseName="/services/"
                            reviewId={props.match.params.reviewId}
                            isPending={true} />
                } />
                <ContentRoute path="/reviews/services/pending" render={
                    () =>
                        <Reviews
                            key="/reviews/services/pending"
                            title="Pending Reviews"
                            caption="Reviews requiring approval"
                            baseName="/services/"
                            isPending={true} />
                } />
                <ContentRoute path="/reviews/services/active/view/:reviewId" render={
                    (props: any) =>
                        <ReviewView
                            key="/reviews/services/active/view"
                            baseName="/services/"
                            reviewId={props.match.params.reviewId}
                            isPending={false} />
                } />
                <ContentRoute path="/reviews/services/active" render={
                    () =>
                        <Reviews
                            key="/reviews/services/active"
                            title="Reviews"
                            caption="Reviews posted on The Right Tool"
                            baseName="/services/"
                            isPending={false} />
                } />

                {/* Apps Special Offers */}
                <ContentRoute path="/special-offers/apps/pending" render={
                    () =>
                        <SpecialOffers
                            key="/special-offers/apps/pending"
                            title="Pending Special Offers"
                            caption="Special Offers requiring approval"
                            baseName="/apps/"
                            isPending={true} />
                } />
                <ContentRoute path="/special-offers/apps/active" render={
                    () =>
                        <SpecialOffers
                            key="/special-offers/apps/active"
                            title="Special Offers"
                            caption="Special Offers"
                            baseName="/apps/"
                            isPending={false} />
                } />

                {/* Services Special Offers */}
                <ContentRoute path="/special-offers/services/pending" render={
                    () =>
                        <SpecialOffers
                            key="/special-offers/services/pending"
                            title="Pending Special Offers"
                            caption="Special Offers requiring approval"
                            baseName="/services/"
                            isPending={true} />
                } />
                <ContentRoute path="/special-offers/services/active" render={
                    () =>
                        <SpecialOffers
                            key="/special-offers/services/active"
                            title="Special Offers"
                            caption="Special Offers"
                            baseName="/services/"
                            isPending={false} />
                } />

                {/* Claims */}
                <ContentRoute path="/claims/apps" render={
                    () =>
                        <Claims
                            title="Pending Claims"
                            caption="Claims requiring approval"
                            baseName="/apps/"
                            query={window.location.search || defaultQuery} />
                } />

                {/* Metadata */}
                <ContentRoute path="/categories/view/:categoryId" render={
                    (props: any) =>
                        <Category
                            categoryId={props.match.params.categoryId} />
                } />
                <ContentRoute path="/categories" render={
                    () =>
                        <Categories />
                } />
                <ContentRoute path="/industries" render={
                    () =>
                        <Industries />
                } />
                <ContentRoute path="/locations" render={
                    () =>
                        <Locations />
                } />

                {/* Account */}
                <ContentRoute path="/account" component={AccountPage} />

                {/* 404 */}
                <ContentRoute component={Error404} />
            </Switch>
        </Suspense>
    );
}
