import React, { ReactElement } from "react";

import "./Toggle.scss";

export interface IToggle {
    checked: boolean;
    onChange: (checked: boolean) => void;
    leftLabel?: string;
    rightLabel?: string;
}

export const Toggle = ({
    checked,
    onChange,
    leftLabel,
    rightLabel,
}: IToggle): ReactElement => {
  return (
    <div className="nv-toggle">
        {leftLabel && <span className="nv-toggle__caption" onClick={() => onChange(false)}>{leftLabel}</span>}

        <label className="nv-toggle__switch">
            <input type="checkbox" checked={checked} onChange={() => onChange(!checked)} />
            <span className="nv-toggle__switch__control">
                <span className="nv-toggle__switch__control__ball"></span>
            </span>
        </label>

        {rightLabel && <span className="nv-toggle__caption" onClick={() => onChange(true)}>{rightLabel}</span>}
    </div>
  );
};

export default Toggle;
