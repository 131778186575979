export function getQueryParams() {
    // via https://gist.github.com/cvan/38fa77f1f28d3eb9d9c461e1d0d0d7d7
    var queryParams = window.location.search.substr(1).split('&').reduce(function (qs, query) {
        var chunks = query.split('=');
        var key = chunks[0];
        var value = decodeURIComponent(chunks[1] || '');
        var valueLower = value.trim().toLowerCase();
        if (valueLower === 'true' || value === 'false') {
            value = value === 'true';
        } else if (!isNaN(Number(value))) {
            value = Number(value);
        }
        return (qs[key] = value, qs);
    }, {});

    return queryParams;
}