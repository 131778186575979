import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { getListing, patchItem, getListings, deleteItem, createReview } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Loading from "../../Loading";

import { IAppListing } from "../../../interface/IAppListing";
import { IReview } from "../../../interface/IReview";

import "./Reviews.scss";

export interface IReviewViewProps {
  reviewId?: string;
  baseName: string;
  isPending: boolean;
}

export const ReviewView = (props: IReviewViewProps) => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Review");

  const { reviewId, baseName, isPending } = props;
  const isNew = !reviewId;

  const [isLoaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [review, setReview] = useState<IReview>({} as any);

  const isNewListing = !review.url;

  const [message, setMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: "info",
    message: "",
  });
  const [redirectTo, redirect] = useState("");
  const [apps, setApps] = useState<IAppListing[]>([]);

  const initialValues = {
    comment: "",
  };
  const Schema = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
  });
  const getInputClasses = (fieldname: string, disableTouch?: boolean) => {
    if (((formik.touched as any)[fieldname] || disableTouch) && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if (((formik.touched as any)[fieldname] || disableTouch) && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setMessage({
        type: "info",
        message: "",
      });

      const form = document.getElementById("listing-form") as HTMLFormElement;
      const formData = new FormData(form);

      let baseFunction;
      if (isNewListing) baseFunction = createReview(formData, baseName);
      else baseFunction = patchItem(review, formData);

      baseFunction
        .then((reviewInfo) => {
          setSubmitting(false);
          setReview(reviewInfo);
          setStatus("success");
          setMessage({
            type: "success",
            message: (user && user.admin) ? "Review updated." : "Review successfully submitted for review."
          });

          if (isNewListing) {
            redirect(`/reviews${baseName}${isPending ? "pending" : "active"}`);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(err);
          console.log({err});
          if (err.smart) {
            setMessage({
              type: "danger",
              message: "Could not create or update review. See below for more information.",
            });
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
            setMessage({
              type: "danger",
              message: err.message,
            });
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const checkDelete = () => {
    if (confirm(`Delete review?`)) {
      setLoading(true);
      deleteItem(review).then(() => {
        redirect(`/reviews${baseName}active`);
      });
    }
  }

  React.useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);

      getListings(baseName)("").then(setApps);

      if (isNew) {
        setLoading(false);
      } else if(!review.listing) {
        setLoading(true);

        const baseFunction = getListing<IReview>(`/reviews${baseName}${reviewId}/`);

        baseFunction
          .then((reviewInfo) => {
            formik.setValues(reviewInfo as any);
            setReview(reviewInfo);
            setLoading(false);
          });
      }
    }
  }, [reviewId, isNew, formik, isLoaded]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  if (loading) {
    return <Loading />;
  }

  console.log({ errors });

  return (
    <>
      <form
        className="card card-custom mb-0"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Review
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {isNewListing ? "Post a" : "Update"} review for {
                (apps && apps.find((a) => a.url === review.listing)?.name) || "a listing"
              }
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={formik.isSubmitting}
            >
              {isNew ? (user.admin ? "Post reivew" : "Submit for review") : "Save changes"}
            </button>
            <Link
              to={`/reviews${baseName}${isPending ? "pending" : "active"}`}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            {message && message.message &&
              <div className={`mb-10 alert alert-custom alert-light-${message.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">{message.message}</div>
              </div>}

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Rating
              </label>
              <div className="col-lg-9 validated">
                <input type="hidden" name="rating" value={review.rating || 0} />
                {
                  [1,2,3,4,5].map((n, i) =>
                    <span
                      key={n}
                      className={
                        [
                          "flaticon-star",
                          "review-star",
                          review.rating > i && "review-star--active",
                        ]
                          .filter((c) => !!c)
                          .join(" ")
                      }
                      title={`Rate ${n} star${n === 1 ? "" : "s"}`}
                      onClick={() => {
                        setReview({
                          ...review,
                          rating: n,
                        });
                      }}></span>
                )}

                {errors.rating ? (
                  <div className="invalid-feedback">
                    {errors.rating}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Comment
              </label>
              <div className="col-lg-9">
                <textarea
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "comment"
                  )}`}
                  {...formik.getFieldProps("comment")}
                />
                {touched.comment && errors.comment ? (
                  <div className="invalid-feedback">
                    {errors.comment}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                App
              </label>
              <div className="col-lg-9 validated">
                {apps && apps.map((app) => {
                  const checked = review.listing === app.url;

                  return (
                    <label key={app.url} className="d-flex align-items-center">
                      <label
                        className="checkbox checkbox-lg checkbox-circle flex-shrink-0 m-0 mr-4">
                        <input
                          type="radio"
                          name="listing"
                          value={app.url}
                          onChange={() => {
                            setReview({
                              ...review,
                              listing: app.url,
                            });
                          }}
                          checked={checked}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>{app.name}</span>
                      </div>
                    </label>
                  );
                })}
                {errors.listing ? (
                  <div className="invalid-feedback">
                    {errors.listing}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>

      {review.url &&
        <div className="card card-custom mt-5">
          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Danger Zone
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Permanently delete this review
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={checkDelete}
                className="btn btn-danger">
                {"Delete"}
              </button>
            </div>
          </div>
          {/* end::Header */}
        </div>
      }
    </>
  );
};
