export const getURLParam = (paramName: string) => {
    const searchString = window.location.search.substring(1);
    const params = searchString.split("&");

    let i = 0;
    let val = undefined;

    for (i = 0; i < params.length; i++) {
        val = params[i].split("=");
        if (val[0] == paramName) {
            return unescape(val[1]);
        }
    }

    return null;
};
