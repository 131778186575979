import React, { useEffect, useState } from "react";
import { deleteItem, addTeamMember } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { IMAGE_TYPES } from "../../constraints";

import { IServiceListing } from "../../../interface/IServiceListing";
import { ITeamMember } from "../../../interface/ITeamMember";

export interface ITeamProps {
  listing: IServiceListing;
  team: ITeamMember[];
  setTeam: (team: ITeamMember[]) => void;
  setMessage: (message: any) => void;
}

export const Team = ({ listing, team, setTeam, setMessage }: ITeamProps) => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  const [formOpen, openForm] = useState(false);

  const isNewListing = !listing.url;

  const initialValues = {
    name: "",
    position: "",
    description: "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string().required("Team member name is required"),
    position: Yup.string().required("Team member position is required"),
    description: Yup.string().required("Team member description is required"),
    photo: Yup
      .mixed()
      .test(
        "fileFormat",
        "Unsupported Format: Must be JPEG or PNG",
        () => {
          const fileInput = document.getElementById("listing-team-photo") as HTMLInputElement;
          const files = Array.from(fileInput.files || [] as any as FileList);
          const badFiles = files.filter((f) => !IMAGE_TYPES.includes(f.type));
          return badFiles.length === 0;
        }
      ),
  });
  const getInputClasses = (fieldname: string, disableTouch?: boolean) => {
    if (((formik.touched as any)[fieldname] || disableTouch) && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if (((formik.touched as any)[fieldname] || disableTouch) && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const form = document.getElementById("team-form") as HTMLFormElement;
      const formPhoto = document.getElementById("listing-team-photo") as HTMLInputElement;

      const formData = new FormData(form);
      if (!formPhoto.value) {
        formData.delete("photo");
      }

      addTeamMember(formData, listing)
        .then((teamMember) => {
          setSubmitting(false);
          openForm(false);
          setTeam([
            ...team,
            teamMember,
          ]);
          setStatus("success");
        })
        .catch((err) => {
          console.log({err});
          setSubmitting(false);
          setStatus(err);
          if (err.smart) {
            setMessage({
              type: "danger",
              message: "Could not add team member. See below for more information.",
            });
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
            setMessage({
              type: "danger",
              message: err.message,
            });
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const checkDelete = (member: ITeamMember) => {
    return () => {
      if (confirm(`Remove ${member.name}?`)) {
        formik.setSubmitting(true);
        deleteItem(member).then(() => {
          formik.setSubmitting(false);
          setTeam(team.filter((t) => t.url !== member.url));
        });
      }
    };
  }

  if (isNewListing) {
    return (
      <div className="card card-custom mt-5">
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h4 className="card-label font-weight-bolder text-dark">
              Team Members
            </h4>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {"Your service's team"}
            </span>
          </div>

          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={true}
            >
              {"Add team member..."}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body pt-0 pb-3">
            <div className="alert mt-5 mb-2 text-center">You may add team members after creating this cloud expert listing.</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card card-custom mt-5">
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h4 className="card-label font-weight-bolder text-dark">
              Team Members
            </h4>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {"Your service's team"}
            </span>
          </div>

          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              onClick={() => openForm(true)}
            >
              {"Add team member..."}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body pt-0 pb-0">
            <div className="tab-content" style={{
              opacity: formik.isSubmitting ? 0.5 : 1
            }}>
              {team.length > 0 &&
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center mb-0">
                    <tbody>
                      {team.map((member) =>
                        <tr key={member.url}>
                          <td className="pl-0 py-8">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-50 symbol-light mr-4">
                                <span className="symbol-label" style={{
                                    backgroundImage: `url(${member.photo})`
                                  }}>&nbsp;</span>
                              </div>
                              <div>
                                <h5 className="text-dark-50 mb-1 font-size-lg">
                                  <span className="font-weight-bolder text-dark-75">{member.name}</span>
                                  {", "}
                                  <em>{member.position}</em>
                                </h5>
                                <span className="text-dark-75 mb-1 font-size-lg">{member.description}</span>
                              </div>
                            </div>
                          </td>
                          <td className="pr-0 text-right">
                            <span onClick={checkDelete(member)} className="btn btn-light-danger font-weight-bolder font-size-sm">Remove</span>
                          </td>
                        </tr>
                      )}
                  </tbody>
                  </table>
                </div>
              }

              {team.length === 0 &&
                <div className="alert mt-5 mb-2 text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-success"
                    onClick={() => openForm(true)}
                  >
                    {"Add team member..."}
                  </button>
                </div>}
            </div>
          </div>
        </div>
      </div>

      <div className={[
        "form-modal",
        formOpen && "form-modal--open",
      ].filter((c) => c).join(" ")}>
        <div className="form-modal__backdrop" onClick={() => openForm(false)}>&nbsp;</div>

        <div className="form-modal__content">
          <form
            className="card card-custom mb-0"
            onSubmit={formik.handleSubmit}
            id="team-form">
            <div className="card card-custom">
              {/* begin::Header */}
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h4 className="card-label font-weight-bolder text-dark">
                    Team Member
                  </h4>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">
                    {"Add a team member"}
                  </span>
                </div>

                <div className="card-toolbar">
                  <button
                    type="submit"
                    className="btn btn-success mr-2"
                    disabled={
                      formik.isSubmitting || (formik.touched && !formik.isValid)
                    }
                  >
                    {"Add team member"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => openForm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {formik.isSubmitting && <div className="card-body__overlay">&nbsp;</div>}

                <div className="tab-content">
                  <div className="table-responsive">
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Name
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Jane Smith"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "name"
                          )}`}
                          {...formik.getFieldProps("name")}
                        />
                        {touched.name && errors.name ? (
                          <div className="invalid-feedback">
                            {errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Position
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Head of Department"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "position"
                          )}`}
                          {...formik.getFieldProps("position")}
                        />
                        {touched.position && errors.position ? (
                          <div className="invalid-feedback">
                            {errors.position}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Description
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="About this person..."
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "description"
                          )}`}
                          {...formik.getFieldProps("description")}
                        />
                        {touched.description && errors.description ? (
                          <div className="invalid-feedback">
                            {errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Photo
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="file"
                          accept="image/*"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "photo",
                            true,
                          )}`}
                          name="photo"
                          id="listing-team-photo"
                          onChange={formik.handleChange}
                        />
                        {errors.photo ? (
                          <div className="invalid-feedback">
                            {errors.photo}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Body */}
              {/* end::Form */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
