import React, { useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as auth from "../Auth";
import { injectIntl } from "react-intl";
import { createSubscription, getPaymentMethods, getSubscriptionInfo } from "../Auth/_redux/authCrud";
import Loading from "../../Loading";
import { Redirect } from "react-router-dom";
import { SITE_PLANS } from "../../constraints";
import { ICard } from "../../../interface/ICard";

const plans = SITE_PLANS;

function FreeTrial() {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const [paymentMethods, setPaymentMethods] = useState<ICard[] | null>(null);
  React.useEffect(() => {
    getPaymentMethods().then(setPaymentMethods);
  }, []);

  const plan = plans.annually;

  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  React.useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  React.useEffect(() => {
    getSubscriptionInfo()
      .then((res) => {
        setHasSubscription(res !== 404);
        setSubscriptionInfo(res.subscription);
      });
  }, []);

  if (subscriptionInfo === null || paymentMethods === null || loading) {
    return <Loading />;
  }

  if (hasSubscription || user.admin) {
    return <Redirect to="/account/subscription" />;
  }

  if (paymentMethods.length === 0) {
    return <Redirect to="/account/payment_methods/trial" />;
  }

  const numberOrUnlimited = (number: number) => {
    if (number === -1) {
      return "Unlimited*";
    }
    return number;
  };

  const handleSubmit = () => {
    createSubscription({
      token: selectedPaymentMethod,
      coupon: undefined,
      term: "annually",
      trial: true,
    })
      .then(() => window.location.reload())
      .catch((err) => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="card card-custom mb-3">
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">{plan.title}</h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">Billed {plan.intervalText}</span>
          </div>
        </div>

        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <h2 className="text-dark-75 font-weight-bolder font-size-h3">3 months free trial</h2>
            {
              plan.comparePrice
                ? <p className="text-muted mb-3">Renews at ${plan.comparePrice.toFixed(2)}/{plan.interval}</p>
                : null }

            <div className="mb-2">
              <span className="text-dark-50 font-weight-bold">{numberOrUnlimited(plan.features.appListings)}</span>{" "}
              <span className="text-muted font-weight-bold">app listings</span>
            </div>

            <div className="mt-2">
              <span className="text-dark-50 font-weight-bold">{numberOrUnlimited(plan.features.serviceListings)}</span>{" "}
              <span className="text-muted font-weight-bold">cloud expert listings</span>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-custom mb-5">
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="row m-0">
              <select
                onChange={(ev) => {
                  setSelectedPaymentMethod(ev.target.value);
                }}
                className="form-control form-control-lg form-control-solid"
                style={{
                  flex: 1,
                }}>
                <option defaultChecked disabled={!!selectedPaymentMethod} value="">--- Select payment method ---</option>
                {paymentMethods.map((paymentMethod) =>
                  <option
                    key={paymentMethod.id}
                    value={paymentMethod.id}
                    selected={paymentMethod.id === selectedPaymentMethod}>
                      {paymentMethod.card.brand} {paymentMethod.card.last4}
                    </option>
                )}
              </select>

              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-success ml-3"
                disabled={!selectedPaymentMethod}>
                Start free trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(FreeTrial));
