import React, { useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout, LayoutSplashScreen} from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import { getUserByToken } from "./modules/Auth/_redux/authCrud";
import { connect } from "react-redux";
import * as auth from "./modules/Auth/_redux/authRedux";
import { CreateBaseSiteUrl } from "./constraints";
import { getURLParam } from "./services/getURLParam";

function Routes(props: any) {
    const { fulfillUser } = props;
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    const authToken = useSelector(
        ({ auth: userAuth }: any) => {
            (window as any).API_TOKEN = userAuth.authToken || null;
            return userAuth.authToken;
        },
        shallowEqual
    );

    const returnTo = getURLParam("returnTo");
    React.useEffect(() => {
        if (authToken) {
            setLoading(true);
            getUserByToken()
                .catch(() => {
                    props.logout();
                    setLoggedIn(false);
                    setLoading(false);
                })
                .then((res) => {
                    if (res && res.id) {
                        fulfillUser(res);

                        if (returnTo) {
                            window.location.replace(CreateBaseSiteUrl(returnTo));
                        }
                    }
                    setLoggedIn(!!(res && res.id));
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setLoggedIn(false);
        }
    }, [authToken, fulfillUser]);

    if (returnTo && authToken) {
        return null;
    }

    if (loading) {
        return <LayoutSplashScreen />;
    }

    if (!loggedIn && !window.location.pathname.startsWith("/auth/")) {
        sessionStorage.setItem("nv-redirect_url", window.location.href);
    }

    return (
        <Switch>
            {!loggedIn ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            {!loggedIn ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/registration" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}

export default connect(null, auth.actions)(Routes);
