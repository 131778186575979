import React from "react";
import { UserProfileDropdown } from "../extras/dropdowns/UserProfileDropdown";

export function Topbar() {
  return (
    <div className="topbar">
      <UserProfileDropdown />
    </div>
  );
}
