import { COOKIE_DOMAIN, COOKIE_SECURE } from "../constraints"

export const setCookie = (key: string, value: string, exdays: number) => {
  const d = new Date();
  d.setTime((d.getTime() + (exdays*24*60*60*1000)));
  document.cookie = `${key}=${value};domain=${COOKIE_DOMAIN};path=/;${COOKIE_SECURE ? "secure=true;" : ""}expires=${d.toUTCString()}`;
}

export const getCookie = (key: string) => {
  const name = key + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const removeCookie = (key: string) => setCookie(key, "", -1);

export default {
  set: setCookie,
  get: getCookie,
  remove: removeCookie,
};
