import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import Cookies from "../../../services/Cookies";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: Cookies.get("AUTH_TOKEN"),
};

export const reducer = persistReducer(
  { storage, key: "v705-auth", whitelist: [""] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, redirectUrl } = action.payload;
        const redirectTo = redirectUrl || sessionStorage.getItem("nv-redirect_url");
        const setToken = `Token ${authToken}`;
        window.API_TOKEN = setToken;
        Cookies.set("AUTH_TOKEN", setToken, 14);
        if (redirectTo) {
          sessionStorage.removeItem("nv-redirect_url");
          setTimeout(() => {
            window.location.href = redirectTo;
          }, 1500);
        }
        return { authToken: setToken, user: undefined };
      }

      case actionTypes.Logout: {
        Cookies.remove("AUTH_TOKEN");
        window.API_TOKEN = undefined;
        return { authToken: null };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        gtag("set", "user_properties", {
          user_id: user.id,
        });
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, redirectUrl) => ({ type: actionTypes.Login, payload: { authToken, redirectUrl } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
