import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ProfileCard } from "./components/ProfileCard";
import { useMediaQuery } from "@material-ui/core";

import PersonaInformation from "./PersonaInformation";
import Security from "./Security";
import Subscription from "./Subscription";
import FreeTrial from "./FreeTrial";
import PaymentMethods from "./PaymentMethods";

export default function UserProfilePage() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Account Settings");

  const matches = useMediaQuery("@media (min-width:992px)");
  const [isMdUp, setIsMdUp] = useState(false);
  React.useEffect(() => {
    setIsMdUp(matches);
  }, [matches]);

  return (
    <div className={isMdUp ? `d-flex flex-row` : ""}>
      <ProfileCard fullWidth={!isMdUp} />

      <div className="flex-row-fluid ml-lg-8">
        <Switch>
          {/* start: personal-information */}
          <Redirect
            from="/account"
            exact={true}
            to="/account/personal-information"
          />
          <Route
            path="/account/personal-information"
            component={PersonaInformation}
          />
          {/* end: personal-information */}

          {/* start: security */}
          <Route
            path="/account/security"
            component={Security}
          />
          {/* end: security */}

          {/* start: subscription */}
          <Route
            path="/account/subscription"
            component={Subscription}
          />
          {/* end: subscription */}

          {/* start: subscription */}
          <Route
            path="/account/payment_methods"
            component={PaymentMethods}
          />
          {/* end: subscription */}

          {/* start: trial */}
          <Route
            path="/account/trial"
            component={FreeTrial}
          />
          {/* end: trial */}
        </Switch>
      </div>
    </div>
  );
}
