import React, { ReactElement, useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { Link } from "react-router-dom";
import { IAppListing } from "../../../interface/IAppListing";
import { getListings, patchItem } from "../../modules/Auth/_redux/authCrud";
import Loading from "../../Loading";
import { IClaim } from "../../../interface/IClaim";
import Dropdown from "react-bootstrap/Dropdown";
import { shallowEqual, useSelector } from "react-redux";

export interface IClaimsProps {
  title: string;
  caption: string;
  baseName: string;
  query: string;
}

export const Claims = ({ title, caption, baseName, query }: IClaimsProps): ReactElement => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Claims");

  const [loading, setLoading] = useState(true);
  const [claims, setClaims] = useState<IClaim[]>([]);
  const [listings, setListings] = useState<IAppListing[]>([]);

  const loadListing = () => {
    getListings(baseName)("").then((allListings) => {
      setListings(allListings);
      getListings<IClaim>(`/claims${baseName}`)(query)
        .then((allClaims) => {
          setLoading(false);
          setClaims(allClaims);
        });
    });
  }

  React.useEffect(() => {
    setLoading(true);
    loadListing();
  }, [baseName, query]);

  if (loading) {
    return <Loading />;
  }

  const getListingId = (listing: IAppListing) => {
    const splitUrl = listing.url.split("/");
    return splitUrl[splitUrl.length - 1] || splitUrl[splitUrl.length - 2];
  };

  const setClaimstatus = (claim: IClaim, status: string) => {
    const formData = new FormData();
    formData.append("status", status);
    setLoading(true);
    patchItem(claim, formData).catch(loadListing).then(loadListing);
  }

  return (
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">{title}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">{caption}</span>
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75">Listing</span></th>
                  <th style={{width: 170}}/>
                </tr>
                </thead>
                <tbody>
                  {claims.map((claim) => {
                    const listing = listings.find((l) => l.url === claim.listing);

                    // Hide approved claims
                    if (claim.status === "A") return null;

                    return (
                      <tr key={claim.url}>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              {listing &&
                                <>
                                  {(user && user.admin)
                                    ? <Link to={`${baseName}active/view/${getListingId(listing)}`} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{listing.name}</Link>
                                    : <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{listing.name}</span>
                                  }
                                  <br />
                                </>}
                              <span className="text-dark-75 mb-1 font-size-lg">
                                {claim.created_by && claim.created_by.username && <>
                                  {claim.created_by.username} (<a href={`mailto:${claim.created_by.email}`}>{claim.created_by.email}</a>)
                                </>}
                              </span>
                              {" "}
                              {claim.status === "D" && <span className="badge badge-danger">DECLINED</span>}
                              {claim.status === "P" && <span className="badge badge-info">PENDING</span>}
                            </div>
                          </div>
                        </td>
                        {user && user.admin &&
                          <td className="pr-0 text-right">
                            <Dropdown drop="down" alignRight className="d-sm-none">
                              <Dropdown.Toggle id={`options-${claim.url}`} className="p-4"></Dropdown.Toggle>
                              <Dropdown.Menu className="p-3">
                                <div className="row m-0">
                                  <span onClick={() => setClaimstatus(claim, "A")} className="btn col btn-light-success font-weight-bolder font-size-sm mr-3">Approve</span>
                                  <span onClick={() => setClaimstatus(claim, "D")} className="btn col btn-light-danger font-weight-bolder font-size-sm">Decline</span>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>

                            <div className="d-none d-sm-block">
                              <span onClick={() => setClaimstatus(claim, "A")} className="btn btn-light-success font-weight-bolder font-size-sm mr-3">Approve</span>
                              <span onClick={() => setClaimstatus(claim, "D")} className="btn btn-light-danger font-weight-bolder font-size-sm">Decline</span>
                            </div>
                          </td>
                        }
                      </tr>
                    );
                  })}
               </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};
