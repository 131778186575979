/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function ProfileCard({ fullWidth }) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <>
      {user && (
        <>
          <div
            className={`flex-row-auto w-100 w-lg-250px w-xxl-350px ${fullWidth ? "mb-6" : ""}`}>
            <div className="card card-custom">
              {/* begin::Body */}
              <div className="card-body">
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <NavLink
                      to="/account/personal-information"
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Personal Information
                      </span>
                    </NavLink>
                  </div>

                  <div className="navi-item mb-2">
                    <NavLink
                      to="/account/security"
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Shield-user.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Security
                      </span>
                    </NavLink>
                  </div>

                  <div className="navi-item mb-2">
                    <NavLink
                      to="/account/payment_methods"
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Credit-card.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Payment Methods
                      </span>
                    </NavLink>
                  </div>

                  <div className="navi-item mb-2">
                    <NavLink
                      to="/account/subscription"
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Settings.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Subscription
                      </span>
                    </NavLink>
                  </div>
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
