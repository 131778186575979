import React, { ReactElement, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { Link } from "react-router-dom";
import { IReview } from "../../../interface/IReview";
import { IAppListing } from "../../../interface/IAppListing";
import { getListings, patchItem, deleteItem } from "../../modules/Auth/_redux/authCrud";
import Loading from "../../Loading";
import Dropdown from "react-bootstrap/Dropdown";

import "./Reviews.scss";

export interface IReviewsProps {
  title: string;
  caption: string;
  baseName: string;
  isPending: boolean;
}

export const Reviews = ({ title, caption, baseName, isPending }: IReviewsProps): ReactElement => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Reviews");

  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [listings, setListings] = useState<IAppListing[]>([]);

  const [query] = useState(window.location.search);

  const loadListing = () => {
    getListings(baseName)("").then((allListings) => {
      setListings(allListings);
      getListings<IReview>(`${isPending ? "/pending" : ""}/reviews${baseName}`)(query)
        .then((allReviews) => {
          setLoading(false);
          setReviews(allReviews);
        });
    });
  }

  React.useEffect(() => {
    setLoading(true);
    loadListing();
  }, [baseName, query]);

  if (loading) {
    return <Loading />;
  }

  const getListingId = (listing: IAppListing | IReview) => {
    const splitUrl = listing.url.split("/");
    return splitUrl[splitUrl.length - 1] || splitUrl[splitUrl.length - 2];
  };

  const approveReview = (review: IReview) => {
    const formData = new FormData();
    formData.append("status", "A");
    setLoading(true);
    patchItem(review, formData).catch(loadListing).then(loadListing);
  }

  const declineReview = (review: IReview) => {
    setLoading(true);
    deleteItem(review).catch(loadListing).then(loadListing);
  };

  return (
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">{title}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">{caption}</span>
          </h3>
          <div className="card-toolbar">
            {
              isPending
              ? <Link to={`/reviews${baseName}active`} className="btn btn-secondary font-weight-bolder mr-5 font-size-sm">Active reviews</Link>
              : <Link to={`/reviews${baseName}pending`} className="btn btn-secondary font-weight-bolder mr-5 font-size-sm">View pending</Link>
            }

            <Link to={`/reviews${baseName}new`} className="btn btn-primary font-weight-bolder font-size-sm">Create new</Link>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75">Listing</span></th>
                  <th style={{width: 230}}/>
                </tr>
                </thead>
                <tbody>
                  {reviews.map((review) => {
                    const listing = listings.find((l) => l.url === review.listing);

                    return (
                      <tr key={review.url}>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              {listing &&
                                <>
                                  <Link to={`${baseName}active/view/${getListingId(listing)}`} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{listing.name}</Link>
                                  <br />
                                </>}
                              <span className="text-dark-75 mb-1 font-size-lg">
                                <span className="mr-2" title={`${review.rating} star${review.rating === 1 ? "" : "s"}`}>
                                  {
                                    [1,2,3,4,5].map((n, i) =>
                                      <span className="flaticon-star" key={n} style={
                                        review.rating > i ? {
                                          color: "#fcde02"
                                        } : {
                                          opacity: 0.1
                                        }
                                      }></span>
                                  )}
                                </span>
                                {review.comment}
                              </span>
                              {" "}
                              {review.status === "D" && <span className="badge badge-danger">DECLINED</span>}

                              {
                                review.created_by_obj &&
                                <div className="font-size-sm">by <a href={`mailto:${review.created_by_obj.email}`}>{review.created_by_obj.first_name} &lt;{review.created_by_obj.email}&gt;</a></div>
                              }
                            </div>
                          </div>
                        </td>
                        <td className="pr-0 text-right">
                          <Dropdown drop="down" alignRight className="d-sm-none">
                            <Dropdown.Toggle id={`options-${review.url}`} className="p-4"></Dropdown.Toggle>
                            <Dropdown.Menu className="p-3">
                              {isPending &&
                                <div className="row m-0">
                                  <span onClick={() => approveReview(review)} className="btn col btn-light-success font-weight-bolder font-size-sm mr-3">Approve</span>
                                  <span onClick={() => declineReview(review)} className="btn col btn-light-danger font-weight-bolder font-size-sm">Decline</span>
                                </div>
                              }
                              <Link to={`/reviews${baseName}${isPending ? "pending" : "active"}/view/${getListingId(review)}`} className="btn col btn-light-primary font-weight-bolder font-size-sm mt-3">Edit</Link>
                            </Dropdown.Menu>
                          </Dropdown>

                          <div className="d-none d-sm-block">
                            <Link to={`/reviews${baseName}${isPending ? "pending" : "active"}/view/${getListingId(review)}`} className="btn btn-light-primary font-weight-bolder font-size-sm mr-3">Edit</Link>
                            {isPending && <>
                              <span onClick={() => approveReview(review)} className="btn btn-light-success font-weight-bolder font-size-sm mr-3">Approve</span>
                              <span onClick={() => declineReview(review)} className="btn btn-light-danger font-weight-bolder font-size-sm">Decline</span>
                            </>}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
               </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};
