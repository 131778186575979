import React, { useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as auth from "../Auth";
import { injectIntl } from "react-intl";
import { getPaymentMethods, createPaymentMethod, deletePaymentMethod, getStripeUser } from "../Auth/_redux/authCrud";
import Loading from "../../Loading";
import { loadStripe, StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { AppBroadcastChannel, AppBroadcastChannelEvent, STRIPE_KEY } from "../../constraints";
import { ICard } from "../../../interface/ICard";
import { IStripeUser } from "../../../interface/IStripeUser";
import { ContentRoute } from "../../../_metronic/layout";
import { Redirect } from "react-router";

const stripePromise = loadStripe(STRIPE_KEY);

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  classes: {
    base: "form-control form-control-lg form-control-solid",
    invalid: "is-invalid",
  },

  style: {
    base: {
      color: '#222222',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#F64E60',
      iconColor: '#F64E60'
    }
  }
};

function PaymentMethods() {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethods, setPaymentMethods] = useState<ICard[] | null>(null);
  React.useEffect(() => {
    getPaymentMethods().then(setPaymentMethods);
  }, []);

  const [stripeUser, setStripeUser] = useState<IStripeUser | 404 | null>(null);
  React.useEffect(() => {
    getStripeUser().then(setStripeUser);
  }, []);

  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  React.useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  if (loading || stripeUser === null || paymentMethods === null) {
    return <Loading />;
  }

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  const handleSubmit = async () => {
    if (!elements || !stripe) {
      throw new Error("elements or stripe null");
    }

    const card = elements.getElement(CardElement);
    if (!card) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error || !paymentMethod) {
      // Inform the user if there was an error.
      setError(error && error.message || "An error occurred");
    } else {
      setError(null);
      setLoading(true);

      createPaymentMethod({
        token: paymentMethod.id,
      })
        .then(() => {
          const event = new CustomEvent(AppBroadcastChannelEvent.CardAdded);
          if (AppBroadcastChannel) AppBroadcastChannel.dispatchEvent(event);
          window.location.reload();
        })
        .catch((err) => {
          alert(err.message);
          setLoading(false);
        });
    }
  }

  const checkDelete = (id: string) => {
    if (confirm(`Delete payment method?`)) {
      setLoading(true);
      deletePaymentMethod(id).then(() => {
        window.location.reload();
      });
    }
  }

  return (
    <>
      <ContentRoute path="/account/payment_methods/trial">
        {
          paymentMethods.length > 0 &&
          <Redirect to="/account/trial" />
        }
        <div
          className="alert alert-custom alert-light-info fade show mb-3"
          role="alert"
        >You must add a payment method to your account before you can start a free trial.</div>
      </ContentRoute>

      <ContentRoute path="/account/payment_methods/subscription">
        {
          paymentMethods.length > 0 &&
          <Redirect to="/account/subscription" />
        }
        <div
          className="alert alert-custom alert-light-info fade show mb-3"
          role="alert"
        >You must add a payment method to your account before you can start a subscription.</div>
      </ContentRoute>

      <div className="card card-custom mb-5">
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">Payment Method</h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">Add a new payment method to your account</span>
          </div>
          
          <div className="card-toolbar">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-success"
              disabled={!!error}>
              Add payment method
            </button>
          </div>
        </div>

        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
            />
            {error && <div className="invalid-feedback" role="alert">{error}</div>}
          </div>
        </div>
      </div>

      {
        paymentMethods.map((paymentMethod) => {
          const defaultMethod =
            paymentMethod.id === (
              stripeUser !== 404 &&
              stripeUser.invoice_settings &&
              stripeUser.invoice_settings.default_payment_method);

          return (
            <div className="card card-custom mb-5" key={paymentMethod.id}>
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">
                    {paymentMethod.card.brand}
                    {" "}
                    {paymentMethod.card.last4}

                    {
                      defaultMethod &&
                      <>
                        {" "}
                        <span className="flaticon-star" style={{ color: "#fcde02" }} title="Default payment method"></span>
                      </>
                    }
                  </span>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">exp. {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</span>
                </div>

                {
                  !defaultMethod &&
                  <div className="card-toolbar">
                    <button
                      type="button"
                      onClick={() => checkDelete(paymentMethod.id)}
                      className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                }
              </div>
            </div>
          );
        })
      }
    </>
  );
}

const PaymentMethodsWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethods />
    </Elements>
  );
}

export default injectIntl(connect(null, auth.actions)(PaymentMethodsWrapper));
