import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as auth from "../Auth";
import { changePassword } from "../Auth/_redux/authCrud";
import { injectIntl } from "react-intl";

const Security = (props: any) => {
  const { intl } = props;

  // Fields
  const [loading, setloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // UI Helpers
  const initialValues = {
    old_password: "",
    password: "",
    password_confirm: "",
  };
  const Schema = Yup.object().shape({
    old_password: Yup.string().required("Current password is required"),
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .required("Password is required"),
    password_confirm: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });
  const getInputClasses = (fieldname: keyof typeof initialValues) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  // Methods
  const saveUser = (values: any, setStatus: (status: any) => void, setSubmitting: (setTo: boolean) => void) => {
    setloading(true);
    setIsSuccess(false);

    changePassword(values)
      .then(() => {
        setIsSuccess(true);
        formik.resetForm();
      })
      .catch((err) => {
        if (err.smart) {
          setStatus(
            intl.formatMessage({
              id: "AUTH.REGISTER.FAILED",
            })  
          );
          Object.entries(err.json).forEach(([key, value]) => {
            formik.setFieldError(key, typeof value === "string" ? value : (value as any).join(" "));
          });
        } else {
          setStatus(err.message);
        }
        setSubmitting(false);
        setloading(false);
      });
  };

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Account Security
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your account password
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/account/"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Alert */}
          {isSuccess && (
            <div
              className="alert alert-custom alert-light-success fade show mb-10"
              role="alert"
            >
              <div className="alert-text font-weight-bold">Your password has been changed.</div>
              <div className="alert-close" onClick={() => setIsSuccess(false)}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )}
          {/* end::Alert */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Current Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Current Password"
                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                  "old_password"
                )}`}
                {...formik.getFieldProps("old_password")}
              />
              {formik.touched.old_password &&
              formik.errors.old_password ? (
                <div className="invalid-feedback">
                  {formik.errors.old_password}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              New Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="New Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "password"
                )}`}
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Verify Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Verify Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "password_confirm"
                )}`}
                {...formik.getFieldProps("password_confirm")}
              />
              {formik.touched.password_confirm && formik.errors.password_confirm ? (
                <div className="invalid-feedback">
                  {formik.errors.password_confirm}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
}

export default injectIntl(connect(null, auth.actions)(Security));
