import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSubheader } from "../../../_metronic/layout";
import { createListing, getIndustries, getListing, patchItem, getListings, getLocations, deleteItem, getCategories } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import Loading from "../../Loading";
import { IMAGE_TYPES } from "../../constraints";

import { Team } from "./TeamComponent";
import { SpecialOffers } from "./SpecialOffersComponent";

import { IAppListing } from "../../../interface/IAppListing";
import { IIndustry } from "../../../interface/IIndustry";
import { IServiceListing } from "../../../interface/IServiceListing";
import { ILocation } from "../../../interface/ILocations";
import { ITeamMember } from "../../../interface/ITeamMember";
import { ISpecialOffer } from "../../../interface/ISpecialOffer";
import { ICategory } from "../../../interface/ICategory";

export const ServiceView = (props: any) => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Listing");

  const [query] = useState(window.location.search);

  const [isLoaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [listing, setListing] = useState<IServiceListing>({} as any);
  const [team, setTeam] = useState<ITeamMember[]>([]);
  const [specialOffers, setSpecialOffers] = useState<ISpecialOffer[]>([]);

  const isNewListing = !listing.url;

  const [message, setMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: "info",
    message: "",
  });
  const [redirectTo, redirect] = useState("");
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [apps, setApps] = useState<IAppListing[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [listingStatus, setListingStatus] = useState("P");
  const [nzOwned, setNzOwned] = useState<boolean>(false);

  const initialValues = {
    name: "",
    short_description: "",
    long_description: "",
    link: "",
    free_trial: "",
    pricing: "",
    email: "",
    phone: "",
    status: "P",
    admin_note: "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string().required("Listing name is required"),
    short_description: Yup.string().required("Short description is required"),
    long_description: Yup.string().required("Full description is required"),
    link: Yup.string().url("Must be a valid link").required("Link is required"),
    free_trial: Yup.string().url("Must be a valid link"),
    pricing: Yup.string().required("Pricing is required"),
    email: Yup.string().email("Must be a valid email").required("Email is required"),
    phone: Yup.string(),
    industries: Yup.array(Yup.string()),
    locations: Yup.array(Yup.string()),
    subcategories: Yup.array(Yup.string()),
    logo: Yup
      .mixed()
      .test(
        "fileFormat",
        "Unsupported Format: Must be JPEG or PNG",
        () => {
          const fileInput = document.getElementById("listing-form-logo") as HTMLInputElement;
          const files = Array.from(fileInput.files || [] as any as FileList);
          const badFiles = files.filter((f) => !IMAGE_TYPES.includes(f.type));
          return badFiles.length === 0;
        }
      ),
  });
  const getInputClasses = (fieldname: string, disableTouch?: boolean) => {
    if (((formik.touched as any)[fieldname] || disableTouch) && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if (((formik.touched as any)[fieldname] || disableTouch) && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setMessage({
        type: "info",
        message: "",
      });

      const form = document.getElementById("listing-form") as HTMLFormElement;
      const formLogo = document.getElementById("listing-form-logo") as HTMLInputElement;

      const formData = new FormData(form);
      if (!formLogo.value) {
        formData.delete("logo");
      }
      formData.set("nz_owned", nzOwned ? "true" : "false");

      let baseFunction;
      if (isNewListing) baseFunction = createListing(formData, true);
      else baseFunction = patchItem(listing, formData);

      baseFunction
        .then((listingInfo) => {
          setSubmitting(false);
          setListing(listingInfo);
          setTeam(listingInfo.team || []);
          setSpecialOffers([
            ...listingInfo.special_offers || [],
            ...listingInfo.pending_special_offers || []
          ]);
          setStatus("success");
          setMessage({
            type: "success",
            message: (user && user.admin) ? "Listing updated." : "Listing successfully submitted for review."
          });
          setListingStatus(listingInfo.status as string);

          if (isNewListing) {
            redirect("/services/pending");
          }
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(err);
          if (err.smart) {
            setMessage({
              type: "danger",
              message: "Could not create or update listing. See below for more information.",
            });
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
            setMessage({
              type: "danger",
              message: err.message,
            });
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const { listingId } = props;
  const isNew = !listingId;

  const checkDelete = () => {
    if (confirm(`Delete ${listing.name}?`)) {
      setLoading(true);
      deleteItem(listing).then(() => {
        redirect(`/services/${props.isPending ? "pending" : "active"}`);
      });
    }
  }

  React.useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);

      getIndustries().then(setIndustries);
      getCategories().then(setCategories);

      getListings("/apps/")("").then(setApps);
      getLocations().then(setLocations);

      if (isNew) {
        setLoading(false);
      } else if(!listing.name) {
        setLoading(true);

        let baseFunction;
        if (props.isPending) baseFunction = getListing<IServiceListing>(`/pending/services/${listingId}/`);
        else baseFunction = getListing<IServiceListing>(`/services/${listingId}/`);

        baseFunction
          .then((listingInfo) => {
            const newValues = {
              ...listingInfo,
              industries: listingInfo.industries.map((industry: any) => industry.url) as any,
              locations: listingInfo.locations.map((industry: any) => industry.url) as any,
              apps: listingInfo.apps.map((industry: any) => industry.url) as any,
              admin_note: listingInfo.admin_note || "",
            };

            formik.setValues(listingInfo as any);
            setListing(newValues);
            setNzOwned(listingInfo.nz_owned);
            setTeam(listingInfo.team || []);
            setSpecialOffers([
              ...listingInfo.special_offers || [],
              ...listingInfo.pending_special_offers || []
            ]);
            setLoading(false);
          });
      }
    }
  }, [listingId, isNew, formik, isLoaded]);

  React.useEffect(() => {
    if (formik.values.status !== listingStatus) {
      formik.setFieldValue("status", listingStatus);
    }
  }, [listingStatus]);

  React.useEffect(() => {
    if (listing && listing.categories) {
      setSelectedCategory(listing.categories[0]);
    }
  }, [listing]);

  if (isLoaded && isNewListing && !user.admin && !user.upgraded) {
    return <Redirect to="/paywall/services" />
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <form
        className="card card-custom mb-0"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Listing Information
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Information about this listing
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={
                formik.isSubmitting || (formik.touched && !formik.isValid)
              }
            >
              {isNew ? (user.admin ? "Create listing" : "Submit for review") : "Save changes"}
              {formik.isSubmitting}
            </button>
            <Link
              to={`/apps/active${query}`}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            {message && message.message &&
              <div className={`mb-10 alert alert-custom alert-light-${message.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">{message.message}</div>
              </div>}

            {listing.status === "D" &&
              <div className={`mb-10 alert alert-custom alert-danger alert-dismissible`}>
                <div className="alert-text font-weight-bold">
                  <div>This listing has been declined by an admin. {listing.admin_note ? "The following note was left:" : "No note was left."}</div>
                  <div>{listing.admin_note}</div>
                </div>
              </div>}

            {
              (listing.special_offers || [])
                .filter((s) => s.status === "D")
                .map((specialOffer) =>
                  <div className={`mb-10 alert alert-custom alert-danger alert-dismissible`} key={specialOffer.url}>
                    <div className="alert-text font-weight-bold">
                      <div>The special offer has been declined by an admin. {listing.admin_note ? "The following note was left:" : "No note was left."}</div>
                      <div>{specialOffer.admin_note}</div>
                    </div>
                  </div>)
            }

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Listing name
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  placeholder="My awesome product"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "name"
                  )}`}
                  {...formik.getFieldProps("name")}
                />
                {touched.name && errors.name ? (
                  <div className="invalid-feedback">
                    {errors.name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Logo
              </label>
              <div className="col-lg-9">
                <input
                  type="file"
                  accept="image/*"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "logo",
                    true,
                  )}`}
                  name="logo"
                  id="listing-form-logo"
                  onChange={formik.handleChange}
                />
                {errors.logo ? (
                  <div className="invalid-feedback">
                    {errors.logo}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Short description
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "short_description"
                  )}`}
                  {...formik.getFieldProps("short_description")}
                />
                {touched.short_description && errors.short_description ? (
                  <div className="invalid-feedback">
                    {errors.short_description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Full description
              </label>
              <div className="col-lg-9">
                <textarea
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "long_description"
                  )}`}
                  {...formik.getFieldProps("long_description")}
                />
                {touched.long_description && errors.long_description ? (
                  <div className="invalid-feedback">
                    {errors.long_description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link
              </label>
              <div className="col-lg-9">
                <input
                  type="link"
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "link"
                  )}`}
                  {...formik.getFieldProps("link")}
                />
                {touched.link && errors.link ? (
                  <div className="invalid-feedback">
                    {errors.link}
                  </div>
                ) : null}
              </div>
            </div>
  
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Free trial
              </label>
              <div className="col-lg-9">
                <input
                  type="link"
                  placeholder=""
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "free_trial"
                  )}`}
                  {...formik.getFieldProps("free_trial")}
                />
                {touched.free_trial && errors.free_trial ? (
                  <div className="invalid-feedback">
                    {errors.free_trial}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Pricing
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  placeholder="$29.99/month"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "pricing"
                  )}`}
                  {...formik.getFieldProps("pricing")}
                />
                {touched.pricing && errors.pricing ? (
                  <div className="invalid-feedback">
                    {errors.pricing}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Email
              </label>
              <div className="col-lg-9">
                <input
                  type="email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  {...formik.getFieldProps("email")}
                />
                {touched.email && errors.email ? (
                  <div className="invalid-feedback">
                    {errors.email}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Phone
              </label>
              <div className="col-lg-9">
                <input
                  type="phone"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "phone"
                  )}`}
                  {...formik.getFieldProps("phone")}
                />
                {touched.phone && errors.phone ? (
                  <div className="invalid-feedback">
                    {errors.phone}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Industries
                <br />
                <em className="text-muted">Max 1</em>
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-0"
                  classNamePrefix="select"
                  isLoading={industries.length === 0}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={
                    industries
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  value={
                    industries
                      .filter((app) => (listing.industries || []).includes(app.url))
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  onChange={(value) => {
                    const newApps = value ? [value.value] : [];
                    setListing({
                      ...listing,
                      industries: newApps,
                    });
                  }}
                  noOptionsMessage={() => "No results found"}
                />

                {industries && industries.map((app) => {
                  const checked = ((listing.industries || []) as any as string[]).includes(app.url);
                  return (
                    <input
                      key={app.url}
                      className="d-none"
                      type="checkbox"
                      name="industries"
                      value={app.url}
                      checked={checked}
                      onChange={() => {
                        // null
                      }}
                    />
                  );
                })}

                {touched.industries && errors.industries ? (
                  <div className="invalid-feedback">
                    {errors.industries}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Category
                <br />
                <em className="text-muted">Max 1</em>
              </label>
              <div className="col-lg-9">
                {categories && categories.map((category, i) => (
                  <div key={category.url}>
                    <label key={category.url} className={`d-flex align-items-center ${categories.length === (i + 1) ? "mb-0" : ""}`}>
                      <label className="checkbox checkbox-lg checkbox-circle flex-shrink-0 m-0 mr-4">
                        <input
                          type="radio"
                          name="categories"
                          value={category.url}
                          onChange={() => setSelectedCategory(category.url)}
                          checked={selectedCategory === category.url}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>{category.name}</span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Locations
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-0"
                  classNamePrefix="select"
                  isLoading={locations.length === 0}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={
                    locations
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  value={
                    locations
                      .filter((app) => (listing.locations || []).includes(app.url))
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  onChange={(values) => {
                    const newApps = values ? values.map((value) => value.value) : [];
                    setListing({
                      ...listing,
                      locations: newApps,
                    });
                  }}
                  noOptionsMessage={() => "No results found"}
                />

                {locations && locations.map((app) => {
                  const checked = ((listing.locations || []) as any as string[]).includes(app.url);
                  return (
                    <input
                      key={app.url}
                      className="d-none"
                      type="checkbox"
                      name="locations"
                      value={app.url}
                      checked={checked}
                      onChange={() => {
                        // null
                      }}
                    />
                  );
                })}

                {touched.locations && errors.locations ? (
                  <div className="invalid-feedback">
                    {errors.locations}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Apps
                <br />
                <em className="text-muted">Max 10</em>
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-0"
                  classNamePrefix="select"
                  isLoading={apps.length === 0}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={
                    (listing.apps || []).length >= 10
                      ? []
                      : apps
                        .map((app) => ({
                          label: app.name,
                          value: app.url,
                        }))
                  }
                  value={
                    apps
                      .filter((app) => (listing.apps || []).includes(app.url))
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                      }))
                  }
                  onChange={(values) => {
                    const newApps = values ? values.map((value) => value.value) : [];
                    setListing({
                      ...listing,
                      apps: newApps,
                    });
                  }}
                  noOptionsMessage={() => {
                    if ((listing.apps || []).length >= 10) {
                      return "Max 10 already selected";
                    }

                    return "No results found";
                  }}
                />

                {apps && apps.map((app) => {
                  const checked = ((listing.apps || []) as any as string[]).includes(app.url);
                  return (
                    <input
                      key={app.url}
                      className="d-none"
                      type="checkbox"
                      name="apps"
                      value={app.url}
                      checked={checked}
                      onChange={() => {
                        // null
                      }}
                    />
                  );
                })}

                {touched.apps && errors.apps ? (
                  <div className="invalid-feedback">
                    {errors.apps}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                NZ Owned
              </label>
              <div className="col-lg-9">
                <label className="mb-0 alert alert-custom alert-outline-info">
                  <div className="alert-text font-weight-bold">
                    <label className="d-flex align-items-center m-0">
                      <label className="checkbox checkbox-lg flex-shrink-0 m-0 mr-4">
                        <input
                          type="checkbox"
                          name="nz_owned"
                          onChange={() => {
                            setNzOwned(!nzOwned);
                          }}
                          checked={nzOwned}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>This listing is New Zealand owned and operated</span>
                      </div>
                    </label>
                  </div>
                </label>
              </div>
            </div>

            {user && user.admin && listing.status &&
              <>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Status
                  </label>
                  <div className="col-lg-9">
                    <input type="hidden" {...formik.getFieldProps("status")} />
                    {
                      [
                        ["Pending", "P", "primary"],
                        ["Approved", "A", "success"],
                        ["Declined", "D", "danger"],
                      ].map(([title, code, className]) =>
                        <button key={code} type="button" className={`btn btn-${listingStatus === code ? className : "secondary"} mr-3`} onClick={() => setListingStatus(code)}>{title}</button>
                      )
                    }
                  </div>
                </div>

                {listingStatus === "D" &&
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Reason for decline
                    </label>
                    <div className="col-lg-9">
                      <textarea
                        placeholder=""
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "admin_note"
                        )}`}
                        {...formik.getFieldProps("admin_note")}
                      />
                      {touched.admin_note && errors.admin_note ? (
                        <div className="invalid-feedback">
                          {formik.errors.admin_note}
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              </>
            }
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>

      {/* Team component */}
      <Team
        listing={listing}
        team={team}
        setTeam={setTeam.bind(this)}
        setMessage={setMessage.bind(this)}
      />

      {/* Special Offers component */}
      <SpecialOffers
        listing={listing}
        listingType="services"
        specialOffers={specialOffers}
        setSpecialOffers={setSpecialOffers.bind(this)}
        setMessage={setMessage.bind(this)}
      />

      {listing.url &&
        <div className="card card-custom mt-5">
          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Danger Zone
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Permanently delete this listing
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={checkDelete}
                className="btn btn-danger">
                {"Delete"}
              </button>
            </div>
          </div>
          {/* end::Header */}
        </div>
      }
    </>
  );
};
