/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink }  from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { checkIsActive } from "../../../../_helpers";
import { CreateBaseSiteUrl } from "../../../../../app/constraints";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {};
  }, [user]);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
            className="menu-item"
            aria-haspopup="true"
          >
            <a className="menu-link" href={CreateBaseSiteUrl("/")} target="_blank">
              <span className="menu-text">Back to The Right Tool</span>
              <span className="icon"></span>
            </a>
          </li>

          {/* Dashboard */}
          {user && user.admin &&
            <>
              <li className="menu-section ">
                <h4 className="menu-text">Dashboard</h4>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/dashboard">
                  <span className="menu-text">Dashboard</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/featured", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/featured">
                  <span className="menu-text">Featured <span className="badge badge-secondary ml-2">NEW</span></span>
                </NavLink>
              </li>
            </>
          }

          {/* App Listings */}
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">App Listings</h4>
            </li>
            {user && user.admin &&
              <>
                <li
                  className={`menu-item ${getMenuItemActive("/apps/active", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/apps/active?user=all">
                    <span className="menu-text">All Listings</span>
                  </NavLink>
                </li>
              </>
            }
            <li
              className={`menu-item ${getMenuItemActive("/apps/active", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/apps/active?user=me">
                <span className="menu-text">My Listings</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/apps/pending", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={`/apps/pending?user=${(user && user.admin) ? "all" : "me"}`}>
                <span className="menu-text">Pending Listings</span>
              </NavLink>
            </li>
            {/* end:: section */}
          </>

          {/* Service Listings / Cloud Experts */}
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Cloud Experts</h4>
            </li>
            {user && user.admin &&
              <>
                <li
                  className={`menu-item ${getMenuItemActive("/services/active", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/services/active?user=all">
                    <span className="menu-text">All Listings</span>
                  </NavLink>
                </li>
              </>
            }
            <li
                className={`menu-item ${getMenuItemActive("/services/active", false)}`}
                aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/services/active?user=me">
                <span className="menu-text">My Listings</span>
              </NavLink>
            </li>
            <li
                className={`menu-item ${getMenuItemActive("/services/pending", false)}`}
                aria-haspopup="true"
            >
              <NavLink className="menu-link" to={`/services/pending?user=${(user && user.admin) ? "all" : "me"}`}>
                <span className="menu-text">Pending Listings</span>
              </NavLink>
            </li>
          </>

          {/* Super Admin: Reviews */}
          {user && user.admin &&
            <>
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text">Reviews</h4>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/reviews/apps", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/reviews/apps/pending">
                  <span className="menu-text">App Reviews</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/reviews/services", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/reviews/services/pending">
                  <span className="menu-text">Service Reviews</span>
                </NavLink>
              </li>
              {/* end:: section */}
            </>
          }

          {/* Super Admin: Special Offers */}
          {user && user.admin &&
            <>
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text">Special Offers</h4>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/special-offers/apps", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/special-offers/apps/pending">
                  <span className="menu-text">App Offers</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/special-offers/services", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/special-offers/services/pending">
                  <span className="menu-text">Service Offers</span>
                </NavLink>
              </li>
              {/* end:: section */}
            </>
          }

          {/* Super Admin: Claims */}
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">App Claims</h4>
            </li>
            {user && user.admin &&
              <>
                <li
                  className={`menu-item ${getMenuItemActive("/claims/apps", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/claims/apps?user=all">
                    <span className="menu-text">All Claims</span>
                  </NavLink>
                </li>
              </>
            }
            <li
              className={`menu-item ${getMenuItemActive("/claims/apps", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/claims/apps?user=me">
                <span className="menu-text">My Claims</span>
              </NavLink>
            </li>
            {/* end:: section */}
          </>

          {/* Metadata */}
          {user && user.admin &&
            <>
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text">Metadata</h4>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/categories", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/categories">
                  <span className="menu-text">Categories</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/industries", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/industries">
                  <span className="menu-text">Industries</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/locations", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/locations">
                  <span className="menu-text">Locations</span>
                </NavLink>
              </li>
              {/* end:: section */}
            </>
          }

          {/* Account */}
          <>
            {/* begin::section */}
            <li className="d-flex d-lg-none menu-section">
              <h4 className="menu-text">Account</h4>
            </li>
            <li
              className={`d-flex d-lg-none menu-item ${getMenuItemActive("/account", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/account">
                <span className="menu-text">My Account</span>
              </NavLink>
            </li>
            <li
              className={`d-flex d-lg-none menu-item`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/logout">
                <span className="menu-text">Sign Out</span>
              </NavLink>
            </li>
            {/* end:: section */}
          </>
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
