import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { getQueryParams } from "../../../../_metronic/_helpers";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function Login(props) {
  const { intl, onVerifyEmail } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "info",
    message: "",
  });

  const queryParams = getQueryParams();

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      username: queryParams.username || "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setStatus(null);
      setTimeout(() => {
        login(values.username, values.password)
          .then(({ token, redirect_url, verified_email }) => {
            disableLoading();

            // TODO: Remove admin override
            if (verified_email || values.username === "admin") {
              props.login(token, redirect_url);
            } else {
              onVerifyEmail(token);
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  React.useEffect(() => {
    setMessage({
      type: "danger",
      message: formik.status,
    })
  }, [formik.status]);

  React.useEffect(() => {
    if (queryParams.message) {
      switch (queryParams.message) {
        case "register-success":
          setMessage({
            type: "success",
            message:
              intl.formatMessage({
                id: "AUTH.REGISTER.SUCCESS",
              }),
          });
          break;
  
        case "email-verified":
          setMessage({
            type: "success",
            message:
              intl.formatMessage({
                id: "AUTH.VERIFY.SUCCESS",
              }),
          });
          break;

        case "password-reset":
          setMessage({
            type: "success",
            message:
              intl.formatMessage({
                id: "AUTH.RESET.SUCCESS",
              }),
          });
          break;

        default: {}
      }
    }
  }, [queryParams.message, intl]);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.LOGIN.DESC" />
        </p>
        <div>
          <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
          <Link to={`/auth/registration${window.location.search}`} className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
        </div>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {message && message.message &&
          <div className={`mb-10 alert alert-custom alert-light-${message.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">{message.message}</div>
          </div>}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Username"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to={`/auth/forgot-password${window.location.search}`}
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
