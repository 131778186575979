import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";

function VerifyEmailGate({ onBack }) {
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.VERIFY.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.VERIFY.DESC" />
        </p>
      </div>
      {/* end::Head */}

      <div className="form-group d-flex flex-wrap flex-center">
        <button
          type="button"
          className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          onClick={onBack}>
          Back
        </button>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(VerifyEmailGate));
