import fetch from "node-fetch";
import { API_BASE } from "../../../constraints";

import { IAppListing } from "../../../../interface/IAppListing";
import { ICategory } from "../../../../interface/ICategory";
import { IClaim } from "../../../../interface/IClaim";
import { IIndustry } from "../../../../interface/IIndustry";
import { ILocation } from "../../../../interface/ILocations";
import { IReview } from "../../../../interface/IReview";
import { IServiceListing } from "../../../../interface/IServiceListing";
import { ISubcategory } from "../../../../interface/ISubcategory";
import { IProfile } from "../../../../interface/IProfile";
import { ISpecialOffer } from "../../../../interface/ISpecialOffer";

const LOGIN_URL = `${API_BASE}/accounts/login/`;
const REGISTER_URL = `${API_BASE}/accounts/register/`;
const REQUEST_PASSWORD_URL = `${API_BASE}/accounts/send-reset-password-link/`;
const ME_URL = `${API_BASE}/accounts/profile/`;
const CHANGE_PASSWORD_URL = `${API_BASE}/accounts/change-password/`;
const LISTINGS_URL = `${API_BASE}/apps/`;
const SERVICES_URL = `${API_BASE}/services/`;
const SUBSCRIPTION_URL = `${API_BASE}/payments/subscription/`;
const PAYMENT_METHODS_URL = `${API_BASE}/payments/payment_methods/`;

export async function login(email: string, password: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        login: email,
        password,
      })
    })
      .then(async (res) => {
        if (res.ok) {
          const json = await res.json();
          resolve(json);
        } else {
          reject(res.statusText);
        }
      })
      .catch(reject);
  });
}

export async function register({
  first_name,
  last_name,
  email,
  password,
  password_confirm,
  redirect_url,
  newsletter,
}: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirm: string;
  redirect_url: string;
  newsletter: boolean;
}): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(REGISTER_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: email,
        first_name,
        last_name,
        email,
        password,
        password_confirm,
        redirect_url: redirect_url || undefined,
        newsletter,
      })
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (json.username) {
            json["email"] = (json["email"] || []);
            json["email"].push("Email address already in use.");
          }
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function requestPassword(email: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(REQUEST_PASSWORD_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        login: email,
      })
    })
      .then(resolve)
      .catch(reject);
  });
}

export async function getApi<T>(url: string): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function getUserByToken(): Promise<IProfile> {
  return new Promise((resolve, reject) => {
    fetch(ME_URL, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const json = await res.json();
          resolve(json);
        } else {
          reject(new Error(res.statusText));
        }
      })
      .catch(reject);
  });
}

export async function changePassword({
  old_password,
  password,
  password_confirm,
}: {
  old_password: string;
  password: string;
  password_confirm: string;
}): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(CHANGE_PASSWORD_URL, {
      method: "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        old_password,
        password,
        password_confirm,
      })
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
});
}

export async function patchUser({
  first_name,
  last_name,
  email,
}: {
  first_name: string;
  last_name: string;
  email: string;
}): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(ME_URL, {
      method: "PATCH",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        first_name,
        last_name,
        email,
        username: email,
      })
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (json.username) {
            json["email"] = (json["email"] || []);
            json["email"].push("Email address already in use.");
          }
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
});
}

export async function createSubscription({ token, coupon, term, trial }: {
  token: string;
  coupon?: string;
  term: string;
  trial?: boolean;
}, method?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(SUBSCRIPTION_URL, {
      method: method || "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        payment_method_id: token,
        term,
        coupon,
        trial,
      })
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (res.status === 404) {
            resolve(404);
          } else {
            reject();
          }
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function getStripeUser(): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/payments/customer/`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();

        if (res.status === 404) {
          resolve(404);
        } else if (res.ok && json.customer) {
          resolve(json.customer);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function getPaymentMethods(): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(PAYMENT_METHODS_URL, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok && json.payment_methods && json.payment_methods.data) {
          resolve(json.payment_methods.data);
        } else {
          resolve([]);
        }
      })
      .catch(reject);
  });
}

export async function createPaymentMethod({ token }: {
  token: string;
}, method?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(PAYMENT_METHODS_URL, {
      method: method || "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        payment_method_id: token,
      })
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (res.status === 404) {
            resolve(404);
          } else {
            reject();
          }
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function deletePaymentMethod(id: string) {
  return deleteUrl(`${PAYMENT_METHODS_URL}${id}`);
}

export async function getSubscriptionInfo(): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(SUBSCRIPTION_URL, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      }
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (res.status === 404) {
            resolve(404);
          } else {
            reject();
          }
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function cancelSubscription(): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(SUBSCRIPTION_URL, {
      method: "DELETE",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      }
    })
      .then(async (res) => {
        if (res.ok) {
          resolve(true);
        } else {
          if (res.status === 404) {
            resolve(404);
          } else {
            reject();
          }
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export function getListings<T = IAppListing>(base: string): (query: string) => Promise<T[]> {
  return async function getListings(query: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE}${base}${query}`, {
        method: "GET",
        headers: {
          Authorization: (window as any).API_TOKEN,
          "Content-Type": "application/json"
        },
      })
        .then(async (res) => {
          const json = await res.json();
          if (res.ok) {
            resolve(json);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }
}

export function getLocations(): Promise<ILocation[]> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/locations/`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function getListing<T = IAppListing>(listingId: string): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}${listingId}`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function getIndustries(): Promise<IIndustry[]> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/industries/`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function getCategories(): Promise<ICategory[]> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/categories/`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function getSubcategories(): Promise<ISubcategory[]> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/subcategories/`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function patchItem(listing: IAppListing | IServiceListing | IReview | IClaim | IIndustry, formData: FormData | string, headers?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(listing.url, {
      method: "PATCH",
      headers: {
        Authorization: (window as any).API_TOKEN,
        ...headers
      },
      body: formData as any,
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function deleteUrl(link: string): Promise<any> {
  return fetch(link, {
    method: "DELETE",
    headers: {
      Authorization: (window as any).API_TOKEN,
    },
  });
}

export async function deleteItem(listing: IAppListing | IServiceListing | IReview | ICategory | ISubcategory | IIndustry | ISpecialOffer): Promise<any> {
  return deleteUrl(listing.url);
}

export async function postItem(endpoint: string, formData: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}${endpoint}`, {
      method: "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
      },
      body: formData as any,
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function createListing(formData: FormData, isService?: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(isService ? SERVICES_URL : LISTINGS_URL, {
      method: "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
      },
      body: formData as any,
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function createReview(formData: FormData, baseName: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/reviews${baseName}`, {
      method: "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
      },
      body: formData as any,
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject({
            smart: true,
            json,
          });
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again.",
      }));
  });
}

export async function addTeamMember(formData: FormData, service: IServiceListing): Promise<any> {
  formData.append("service", service.url);
  return postItem("/team-members/", formData);
}

export async function addSpecialOffer(formData: FormData, listing: IServiceListing | IAppListing, listingType: "services" | "apps"): Promise<any> {
  formData.append("listing", listing.url);
  return postItem(`/special-offers/${listingType}/`, formData);
}
