import React, { useEffect, useState } from "react";
import { deleteItem, addSpecialOffer, getPaymentMethods, getStripeUser } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SPECIAL_OFFERS, AppBroadcastChannel, AppBroadcastChannelEvent } from "../../constraints";

import { IServiceListing } from "../../../interface/IServiceListing";
import { ISpecialOffer } from "../../../interface/ISpecialOffer";
import { IAppListing } from "../../../interface/IAppListing";
import { ICard } from "../../../interface/ICard";

export interface ISpecialOffersProps {
  listing: IServiceListing | IAppListing;
  listingType: "services" | "apps";
  specialOffers: ISpecialOffer[];
  setSpecialOffers: (team: ISpecialOffer[]) => void;
  setMessage: (message: any) => void;
}

export const SpecialOffers = ({ listing, listingType, specialOffers, setSpecialOffers, setMessage }: ISpecialOffersProps) => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  const [shouldConfirm, showConfirm] = useState(false);

  const [formOpen, openForm] = useState(false);
  React.useEffect(() => {
    showConfirm(false);
  }, [formOpen]);
  React.useEffect(() => {
    if (window.location.pathname.endsWith("/special-offer")) {
      openForm(true);
    }
  }, []);

  const [loading, setLoading] = useState(true);

  const [card, setCard] = useState<ICard | null>(null);

  React.useEffect(() => {
    const loadCard = () =>
      getStripeUser().then((stripeUser) => {
        getPaymentMethods()
        .then((paymentMethods: ICard[]) => {
          const defaultPaymentMethod = paymentMethods.find((paymentMethod) => {
            const defaultMethod =
              paymentMethod.id === (
                stripeUser !== 404 &&
                stripeUser.invoice_settings &&
                stripeUser.invoice_settings.default_payment_method);

            return defaultMethod;
          });

          setCard(defaultPaymentMethod || null);
          setLoading(false);

          if (!defaultPaymentMethod) {
            // Listen for when a new card is added
            if (AppBroadcastChannel) {
              AppBroadcastChannel.addEventListener(AppBroadcastChannelEvent.CardAdded, () => {
                loadCard();
              });
            }
          }
        });
      });

    loadCard();
  }, []);

  const isNewListing = !listing.url;

  const initialValues = {
    title: "",
    description: "",
    label: "",
    link: "",
    duration: 90,
  };
  const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    label: Yup.string().required("Link label is required"),
    link: Yup.string().url("Link is invalid").required("Link is required"),
    duration: Yup.number(),
  });
  const getInputClasses = (fieldname: string, disableTouch?: boolean) => {
    if (((formik.touched as any)[fieldname] || disableTouch) && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if (((formik.touched as any)[fieldname] || disableTouch) && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!shouldConfirm) {
        setSubmitting(false);
        showConfirm(true);
        return;
      }

      const form = document.getElementById("special-offer-form") as HTMLFormElement;

      const formData = new FormData(form);

      addSpecialOffer(formData, listing, listingType)
        .then((specialOffer) => {
          setSubmitting(false);
          openForm(false);
          setSpecialOffers([
            ...specialOffers,
            specialOffer,
          ]);
          setStatus("success");
        })
        .catch((err) => {
          console.log({err});
          setSubmitting(false);
          setStatus(err);
          if (err.smart) {
            setMessage({
              type: "danger",
              message: "Could not add team member. See below for more information.",
            });
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
            setMessage({
              type: "danger",
              message: err.message,
            });
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const checkDelete = (member: ISpecialOffer) => {
    return () => {
      if (confirm(`Remove special offer?`)) {
        formik.setSubmitting(true);
        deleteItem(member).then(() => {
          formik.setSubmitting(false);
          setSpecialOffers(specialOffers.filter((t) => t.url !== member.url));
        });
      }
    };
  }

  if (loading) {
    return (
      <div className="card card-custom mt-5">
        <div className="card card-custom">
          <div className="card-body">  
            <div className="tab-content text-center">
              {"Loading..."}
            </div>
            </div>
        </div>
      </div>
    );
  }

  const renderModal = () => {
    if (!card) {
      return (
        <div className={[
          "form-modal",
          formOpen && "form-modal--open",
        ].filter((c) => c).join(" ")}>
          <div className="form-modal__backdrop" onClick={() => openForm(false)}>&nbsp;</div>
  
          <div className="form-modal__content">
            <div className="card card-custom mb-0">
              <div className="card card-custom">
                {/* begin::Header */}
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h4 className="card-label font-weight-bolder text-dark">
                      Special Offer
                    </h4>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                      {"Get more customers by offering special offers"}
                    </span>
                  </div>
  
                  <div className="card-toolbar">  
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => openForm(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
  
                <div className="card-body">  
                  <div className="tab-content text-center">
                  <div>
                      <span className="card-label font-weight-bolder font-size-lg text-dark">
                        {"Payment method required"}
                      </span>
                    </div>

                    <div>
                      {"You must add a payment method to your account before you can add special offers"}
                    </div>

                    <div className="my-3">
                      <Link
                        to="/account/payment_methods"
                        target="_blank"
                        className="btn btn-outline-success"
                      >
                        {"Add payment method"}
                      </Link>
                    </div>
                  </div>
                </div>
                {/* end::Body */}
                {/* end::Form */}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (specialOffers.length > 0) {
      return (
        <div className={[
          "form-modal",
          formOpen && "form-modal--open",
        ].filter((c) => c).join(" ")}>
          <div className="form-modal__backdrop" onClick={() => openForm(false)}>&nbsp;</div>
  
          <div className="form-modal__content">
            <div className="card card-custom mb-0">
              <div className="card card-custom">
                {/* begin::Header */}
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h4 className="card-label font-weight-bolder text-dark">
                      Special Offer
                    </h4>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                      {"Get more customers by offering special offers"}
                    </span>
                  </div>
  
                  <div className="card-toolbar">  
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => openForm(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
  
                <div className="card-body">  
                  <div className="tab-content text-center">
                    <div>
                      <span className="card-label font-weight-bolder font-size-lg text-dark">
                        {"Special offer exists"}
                      </span>
                    </div>

                    <div>
                      {"Your listing already has a special offer"}
                    </div>
                  </div>
                </div>
                {/* end::Body */}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={[
        "form-modal",
        formOpen && "form-modal--open",
      ].filter((c) => c).join(" ")}>
        <div className="form-modal__backdrop" onClick={() => openForm(false)}>&nbsp;</div>

        <div className="form-modal__content">
          <form
            className="card card-custom mb-0"
            onSubmit={formik.handleSubmit}
            id="special-offer-form">
            <div className="card card-custom">
              {/* begin::Header */}
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h4 className="card-label font-weight-bolder text-dark">
                    Special Offer
                  </h4>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">
                    {"Get more customers by offering special offers"}
                  </span>
                </div>

                <div className="card-toolbar">
                  {!shouldConfirm &&
                    <button
                      type="submit"
                      className="btn btn-success mr-2"
                      disabled={
                        formik.isSubmitting || (formik.touched && !formik.isValid)
                      }
                    >
                      {"Add special offer"}
                    </button>}

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => openForm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {formik.isSubmitting && <div className="card-body__overlay">&nbsp;</div>}

                {shouldConfirm &&
                  <div className="tab-content text-center">
                    <div>
                      <span className="card-label font-weight-bolder font-size-lg text-dark">
                        {`Confirm payment of $`}{SPECIAL_OFFERS[formik.values.duration].price.toFixed(2)}
                      </span>
                    </div>

                    <div className="my-2">
                      <p className="mb-2">Your card ({card.card.brand} {card.card.last4}) will be charged immediately.</p>
                      <p className="mb-0">{"Special offer will be displayed on your listing's page once approved by an admin."}</p>
                    </div>

                    <div className="my-3">
                      <button
                        type="submit"
                        className="btn btn-success"
                      >
                        {"Confirm payment"}
                      </button>
                    </div>

                    <div>
                      <Link to="/account/payment_methods" target="_blank">
                        {"Manage payment methods"}
                      </Link>
                    </div>
                  </div>}

                <div className={[
                  "tab-content",
                  shouldConfirm && "d-none"
                ].filter((c) => c).join(" ")}>
                  <div className="table-responsive">
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Title
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Special Offer!"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "title"
                          )}`}
                          {...formik.getFieldProps("title")}
                        />
                        {touched.title && errors.title ? (
                          <div className="invalid-feedback">
                            {errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Description
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Get a special offer..."
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "description"
                          )}`}
                          {...formik.getFieldProps("description")}
                        />
                        {touched.description && errors.description ? (
                          <div className="invalid-feedback">
                            {errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Link label
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Claim Offer..."
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "label"
                          )}`}
                          {...formik.getFieldProps("label")}
                        />
                        {touched.label && errors.label ? (
                          <div className="invalid-feedback">
                            {errors.label}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Link
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="url"
                          placeholder="https://www..."
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "link"
                          )}`}
                          {...formik.getFieldProps("link")}
                        />
                        {touched.link && errors.link ? (
                          <div className="invalid-feedback">
                            {errors.link}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Duration
                      </label>
                      <div className="col-lg-9">
                        <select
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "duration"
                          )}`}
                          {...formik.getFieldProps("duration")}
                        >
                          {
                            Object.entries(SPECIAL_OFFERS)
                              .map(([duration, durationInfo]) =>
                                <option key={duration} value={duration}>{durationInfo.duration} – ${durationInfo.price.toFixed(2)}</option>
                              )
                          }
                        </select>
                        {touched.duration && errors.duration ? (
                          <div className="invalid-feedback">
                            {errors.duration}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Body */}
              {/* end::Form */}
            </div>
          </form>
        </div>
      </div>
    );
  }

  if (isNewListing) {
    return (
      <div className="card card-custom mt-5">
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h4 className="card-label font-weight-bolder text-dark">
              Special Offers
            </h4>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {"Get more customers by offering special offers"}
            </span>
          </div>

          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={true}
            >
              {"Add special offer..."}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body pt-0 pb-3">
            <div className="alert mt-5 mb-2 text-center">You may add a special offer after creating this listing.</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card card-custom mt-5">
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h4 className="card-label font-weight-bolder text-dark">
              Special Offers
            </h4>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {"Get more customers by offering special offers"}
            </span>
          </div>

          {specialOffers.length === 0 &&
            <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-success mr-2"
                onClick={() => openForm(true)}
              >
                {"Add special offer..."}
              </button>
            </div>}
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body pt-0 pb-0">
            <div className="tab-content" style={{
              opacity: formik.isSubmitting ? 0.5 : 1
            }}>
              {specialOffers.length > 0 &&
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center mb-0">
                    <tbody>
                      {specialOffers.map((member) =>
                        <tr key={member.url}>
                          <td className="pl-0 py-8">
                            <div className="d-flex align-items-center">
                              <div>
                                <h5 className="text-dark-50 mb-1 font-size-lg">
                                  <span className="font-weight-bolder text-dark-75">{member.title}</span>
                                </h5>
                                <span className="text-dark-75 mb-1 font-size-lg">{member.description}</span>

                                <div className="mt-3">
                                  <a href={member.link} target="_blank" rel="nofollow noreferrer" className="btn btn-sm btn-outline-primary">{member.label}</a>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="pr-0 text-right">
                            <span onClick={checkDelete(member)} className="btn btn-light-danger font-weight-bolder font-size-sm">Remove</span>
                          </td>
                        </tr>
                      )}
                  </tbody>
                  </table>
                </div>
              }

              {specialOffers.length === 0 &&
                <div className="alert mt-5 mb-2 text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-success"
                    onClick={() => openForm(true)}
                  >
                    {"Add special offer..."}
                  </button>
                </div>}
            </div>
          </div>
        </div>
      </div>

      {renderModal()}
    </>
  );
};
