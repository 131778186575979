import React, { ReactElement, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { SITE_PLANS } from "../../constraints";
import { Link } from "react-router-dom";

import "./ServicesPaywall.scss";
import Toggle from "../../modules/Toggle/Toggle";

export const ServicesPaywall = (): ReactElement => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Paywall");

  const [quarterly, setQuarterly] = useState(false);
  const [plan, setPlan] = useState(SITE_PLANS.annually);

  React.useEffect(() => {
    setPlan(quarterly ? SITE_PLANS.quarterly : SITE_PLANS.annually);
  }, [quarterly]);

  return (
    <div className="services-paywall">
      <div className="services-paywall__header">
        <img src={toAbsoluteUrl("/media/svg/paywall-top.png")} className="services-paywall__header__image" />
        <h1 className="services-paywall__header__title">Get listed on The Right Tool</h1>
        <p className="services-paywall__header__caption">Your listing will give you the opportunity to acquire high buying intent web traffic and sales leads from small business owners ready to try and buy your solution.</p>
      </div>

      <ul className="services-paywall__benefits">
        <li className="services-paywall__benefits__item">
          <SVG src={toAbsoluteUrl("/media/svg/svg-tick.svg")} className="services-paywall__benefits__item__tick" />
          <span className="services-paywall__benefits__item__caption">Tailored Leads</span>
        </li>

        <li className="services-paywall__benefits__item">
          <SVG src={toAbsoluteUrl("/media/svg/svg-tick.svg")} className="services-paywall__benefits__item__tick" />
          <span className="services-paywall__benefits__item__caption">Local Reviews</span>
        </li>

        <li className="services-paywall__benefits__item">
          <SVG src={toAbsoluteUrl("/media/svg/svg-tick.svg")} className="services-paywall__benefits__item__tick" />
          <span className="services-paywall__benefits__item__caption">Public Profile</span>
        </li>
      </ul>

      <hr className="services-paywall__line" />

      <div className="services-paywall__subscription">
        <Toggle checked={quarterly} onChange={setQuarterly} leftLabel="Annual" rightLabel="Quarterly" />

        <h2 className="services-paywall__subscription__price">
          <span className="services-paywall__subscription__price__price">${plan.price.toFixed(0)}</span>
          <span className="services-paywall__subscription__price__interval">{plan.firstInterval}</span>
        </h2>

        {
          plan.comparePrice
            ? <span className="services-paywall__subscription__compare">Renews at ${plan.comparePrice.toFixed(2)}/{plan.interval}</span>
            : null}

        <Link to={`/account/subscription?quarterly=${quarterly.toString()}`}>
          <button type="button" className="btn btn-primary btn-block services-paywall__subscription__button">
            <span>Create Listing</span>
            <img src={toAbsoluteUrl("/media/svg/svg-arrow.svg")} className="services-paywall__subscription__button__arrow" />
          </button>
        </Link>
      </div>
    </div>
  );
};
