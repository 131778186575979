import React, { useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { postItem, getListing, deleteItem } from "../../modules/Auth/_redux/authCrud";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Redirect } from "react-router-dom";
import Loading from "../../Loading";

import { ICategory } from "../../../interface/ICategory";
import { ISubcategory } from "../../../interface/ISubcategory";

export interface ICategoryProps {
  categoryId: string;
}

export const Category = ({ categoryId }: ICategoryProps): React.ReactElement => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Category");

  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<ICategory>({} as any);
  const [redirectTo, redirect] = useState("");
  const subcategories = category.subcategories;

  const loadCategory =() => {
    setLoading(true);
    getListing<ICategory>(`/categories/${categoryId}`).then((newCategory) => {
      setCategory(newCategory);
      setLoading(false);
    });
  };

  React.useEffect(loadCategory, [categoryId]);

  const initialValues = {
    name: "",
  };
  const Schema = Yup.object().shape({
    name: Yup.string().required("Category name is required"),
  });
  const getInputClasses = (fieldname: string) => {
    if ((formik.touched as any)[fieldname] && (formik.errors as any)[fieldname]) {
      return "is-invalid";
    }

    if ((formik.touched as any)[fieldname] && !(formik.errors as any)[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      const form = document.getElementById("listing-form") as HTMLFormElement;
      const formData = new FormData(form);
      formData.append("category", category.url);

      postItem("/subcategories/", formData)
        .then(() => {
          // formik.resetForm();
          loadCategory();
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(err);
          if (err.smart) {
            Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
              formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
            });
          } else {
            setStatus(err.message);
          }
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const touched = formik.touched as any;
  const errors = formik.errors as any;

  const checkDelete = (item: ICategory | ISubcategory, redirectAfter?: boolean) => {
    return () => {
      if (confirm(`Delete ${item.name}?`)) {
        setLoading(true);
        deleteItem(item).then(() => {
          if (redirectAfter) redirect("/categories");
          else {
            getListing<ICategory>(`/categories/${categoryId}`).then((newCategory) => {
              setCategory(newCategory);
              setLoading(false);
            });
          }
        });
      }
    };
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">{category.name}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">{category.url}</span>
          </h3>
          <div className="card-toolbar">
            <span onClick={checkDelete(category, true)} className="btn btn-danger font-weight-bolder font-size-sm">Delete</span>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75">Subcategory</span></th>
                  <th style={{width: 170}}/>
                </tr>
                </thead>
                <tbody>
                  {subcategories.map((subcategory) => {
                    return (
                      <tr key={subcategory.url}>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">{subcategory.name}</span>
                            </div>
                          </div>
                        </td>
                        <td className="pr-0 text-right">
                          <span onClick={checkDelete(subcategory, false)} className="btn btn-danger font-weight-bolder font-size-sm">Delete</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <form
        className="card card-custom mt-5"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="form-group row mb-0">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Subcategory
              </label>
              <div className="col-lg-9 row">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "name"
                  )}`}
                  style={{
                    flex: 1,
                  }}
                  {...formik.getFieldProps("name")}
                />

                <button
                  type="submit"
                  className="btn btn-success ml-3"
                  disabled={
                    formik.isSubmitting || (formik.touched && !formik.isValid)
                  }
                >
                  {"Add Subcategory"}
                </button>

                {touched.name && errors.name ? (
                  <div className="invalid-feedback">
                    {errors.name}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>
    </>
  );
};
